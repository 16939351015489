.footer-header-row {
  order: 4;
}
.footer-block {
  overflow: hidden;
  color: $FT_pebble;
  h4 {
    @include FT_body_2;
  }
  a {
    color: $FT_white;
  }
  .top {
    font-weight: 100;
    padding: $spacing-block-xs 0;
    background: $FT_footer;
    color: $FT_white;
    @include mobtablet {
      padding: $spacing-mobile-block-m 0 0;
    }

    h3 {
      @include FT_H2;
      color: inherit;
      font-weight: 100;
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .logo {
      margin-bottom: $spacing-block-xxs;
      text-align: right;
    }
  }
  .bottom {
    font-weight: normal;
    padding: $spacing-block-xs 0;
    background: $FT_beige_light;
    color: $FT_footer;
    @include mobtablet {
      padding: $spacing-mobile-block-s 0;
    }

    a {
      color: $FT_orange_link;
      &:hover {
        color: $FT_anthracite;
      }
    }
  }

  .footer-featured {
    color: $FT_pebble;
    @include FT_body_base;
    font-weight: 100;
    a {
      color: $FT_white;
      text-decoration: underline;
      @include desktop {
        &[href^="tel"] {
          color: $FT_pebble;
          text-decoration: none;
        }
      }
    }
  }
}

.footer-heading {
  margin: 0 0 $spacing-block-xs;
  color: $FT_pebble;
  font-weight: 100;
  @include mobtablet {
    @include FT_body_2;
    font-weight: 100;
    margin: 0 0 $spacing-mobile-block-m;
  }
}

.footer-some {
  color: $FT_pebble;
  display: flex;
  justify-content: flex-end;
  .row-lg-hide & {
    justify-content: flex-start;
  }
  margin: 0 0 $spacing-block-xs;
  @include mobtablet {
    margin: $spacing-mobile-block-l 0 $spacing-mobile-block-m;
    padding: $spacing-mobile-block-m 0 0;
  }
  @include mobile {
    margin: $spacing-mobile-block-s 0 $spacing-mobile-block-m;
    border-top: solid 1px $FT_shale;
  }

  a img {
    vertical-align: middle;
  }
  span,
  a {
    vertical-align: middle;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    border-left: solid 1px $FT_shale;
    padding: 0 $spacing-text-m;
    &:last-child {
      border-right: solid 1px $FT_shale;
    }
    @include mobile {
      padding: $spacing-mobile-text-m;
    }
  }
  .social {
    display: flex;
    .social-link {
      display: flex;
      align-items: center;
    }
    .icon {
      width: 25px;
      height: 25px;
      g,
      path {
        fill: $FT_white;
      }
      &:hover {
        g,
        path {
          fill: $FT_orange_link;
        }
      }
    }
  }
}
