// Default 12 columns system.
$fg-columns: 12 !default;
// Default gutter spacing
$fg-gutter: $site-column-gutter !default;

$fg-class-row: "row" !default;
$fg-class-col: "col" !default;
$fg-class-off: "off" !default;
$fg-class-aff: "aff" !default;

$fg-breakpoints: (
  (xs),
  (sm, $screen-sm, $screen-md - 1),
  (md, $screen-md, $screen-lg - 1),
  (lg, $screen-lg)
) !default;

@function get-col-percent($column-num) {
  @return $column-num / $fg-columns * 100%;
}

@mixin spacing($value, $type: margin, $orientation: vertical) {
  @if $orientation == vertical {
    #{ $type }-top: $value;
    #{ $type }-bottom: $value;
  } @else {
    #{ $type }-left: $value;
    #{ $type }-right: $value;
  }
}

/**
 * Row wrapper class, flex box parent.
 */
.#{ $fg-class-row } {
  box-sizing: border-box;
  //	display: -webkit-flex;
  display: flex;
  flex-direction: row;
  //	-webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;

  .#{ $fg-class-row } {
    @include spacing((-$fg-gutter), margin, horizontal);
  }
}

%#{ $fg-class-col } {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  @include spacing(($fg-gutter), padding, horizontal);
}

%#{ $fg-class-col }-flex {
  @extend %#{ $fg-class-col };
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

@mixin grid-base($namespace) {
  .#{ $fg-class-col }-#{$namespace} {
    @extend %#{ $fg-class-col }-flex;
  }

  @for $i from 1 through $fg-columns {
    .#{ $fg-class-col }-#{$namespace}-#{$i} {
      @extend %#{ $fg-class-col };
    }
  }
}

@mixin grid-sizes($namespace) {
  // Run the loop for as many columns as speficied
  @for $i from 1 through $fg-columns {
    .#{ $fg-class-col }-#{$namespace}-#{$i} {
      flex-basis: get-col-percent($i);
      max-width: get-col-percent($i);
    }
  }
}

@mixin grid-offsets($namespace) {
  @for $i from 0 through $fg-columns {
    // Don't include the .off class for the last column
    @if ($i != $fg-columns) {
      .#{ $fg-class-off }-#{$namespace}-#{$i} {
        margin-left: get-col-percent($i);
      }

      .#{ $fg-class-aff }-#{$namespace}-#{$i} {
        margin-right: get-col-percent($i);
      }
    }
  }
}

@each $val in $fg-breakpoints {
  $namespace: nth($val, 1);
  @include grid-base($namespace);
}

@each $val in $fg-breakpoints {
  @if length($val) == 1 {
    $namespace: nth($val, 1);
    @include grid-sizes($namespace);
    @media (max-width: #{$screen-sm - 1}) {
      @include grid-offsets($namespace);
    }
  } @else {
    $namespace: nth($val, 1);
    $size: nth($val, 2);
    @media (min-width: #{$size}) {
      @include grid-sizes($namespace);
      @if length($val) == 2 {
        @include grid-offsets($namespace);
      }
    }
    @if length($val) == 3 {
      $maxsize: nth($val, 3);
      @media (min-width: #{$size}) and (max-width: #{$maxsize}) {
        @include grid-offsets($namespace);
      }
    }
  }
}

@each $val in $fg-breakpoints {
  @if length($val) == 1 {
    $namespace: nth($val, 1);
    @media (max-width: #{$screen-sm - 1}) {
      .#{ $fg-class-row }-#{$namespace}-hide,
      .#{ $fg-class-col }-#{$namespace}-hide {
        display: none;
      }
    }
  } @else if length($val) == 3 {
    $namespace: nth($val, 1);
    $size: nth($val, 2);
    $maxsize: nth($val, 3);
    @media (min-width: #{$size}) and (max-width: #{$maxsize}) {
      .#{ $fg-class-row }-#{$namespace}-hide,
      .#{ $fg-class-col }-#{$namespace}-hide {
        display: none;
      }
    }
  } @else {
    $namespace: nth($val, 1);
    $size: nth($val, 2);
    @media (min-width: #{$size}) {
      .#{ $fg-class-row }-#{$namespace}-hide,
      .#{ $fg-class-col }-#{$namespace}-hide {
        display: none;
      }
    }
  }
}

.row {
  max-width: $site-lg-width;
  margin: 0 auto;

  &.centered-vertical {
    align-items: center;
  }
  &.centered-horizontal {
    justify-content: center;
  }
}

.col {
  display: flex;
  flex-flow: column;
  max-width: $site-lg-width;
  margin: 0 auto;

  > %#{ $fg-class-col } {
    flex-basis: inherit;
  }

  &.centered-horizontal {
    align-items: center;
  }
  &.centered-vertical {
    justify-content: center;
  }
}

// In order for content to take 100% width on devices with ios 8 and lower.
@include mobile {
  .col-xs-12 {
    //-webkit-flex: 1 1 100%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
  }
}

.flex-1 {
  flex: 1;
}

.order-mob-1 {
  @media (max-width: $screen-sm - 1) {
    //-webkit-order: -3;
    order: -3;
  }
}
.order-mob-2 {
  @media (max-width: $screen-sm - 1) {
    //-webkit-order: -2;
    order: -2;
  }
}
.order-mob-3 {
  @media (max-width: $screen-sm - 1) {
    //-webkit-order: -1;
    order: -1;
  }
}

.order-tab-1 {
  @media (max-width: $screen-md - 1) {
    //-webkit-order: -3;
    order: -3;
  }
}
.order-tab-2 {
  @media (max-width: $screen-md - 1) {
    //-webkit-order: -2;
    order: -2;
  }
}
.order-tab-3 {
  @media (max-width: $screen-md - 1) {
    //-webkit-order: -1;
    order: -1;
  }
}
/*
// Mobile first approach
.mobile-only{
	display: none;
	@media (max-width: $screen-sm) {
		display: inherit;
	}
}
.desktop-only{
	display: inherit;
	@media (max-width: $screen-sm) {
		display: none;
	}
}
*/
