.ft-list {
  .ft-list-item {
    display: flex;
    width: 100%;
    &.is-clickable {
      .ft-list-item-simple {
        //padding: $spacing-text-s 0;
        border-bottom: solid 1px $FT_limestone;
        transition: background 0.2s ease-out;
        /*&:hover,
				&.is-expanded {
					background: $FT_grey-light;
				}*/
      }
    }
  }
}

.empty-list-text {
  margin-top: $spacing-text-m;
}

.deviation-page {
  .ingress {
    margin-bottom: $spacing-block-xxs;
  }
  .editorial-text {
    margin-bottom: $spacing-block-xxs;
  }
  .service-disruptions-list-container {
    margin-bottom: $spacing-block-xs;
  }

  // OMG I fucking hate this grid system right now.
  .row .ft-list.service-disruptions-list .row {
    margin: 0;
    .col-xs-12 {
      padding: 0;
    }
  }
}

.ft-list.service-disruptions-list {
  .ft-list-item {
    display: flex;
    flex-flow: column;
    /*> div {
			width: 100%;
		}*/
    .ft-list-item-simple {
      display: flex;
      width: 100%;
      > div {
        width: 100%;
        //padding: 0 $spacing-text-s;
        &:first-child {
          //padding-left: 0;
        }
        &:last-child {
          //padding-right: 0;
        }
      }
    }

    .service-disruptions-title {
      padding: $spacing-text-s 0;
    }
    .service-disruptions-time {
      display: flex;
      flex-flow: row;
      justify-content: flex-end;
      flex: 1;
      @include mobile {
        display: flex;
        flex-flow: column;
      }
    }
    .service-disruptions-fromtime,
    .service-disruptions-totime {
      width: 170px;
      padding: $spacing-text-s;
      @include mobile {
        //display: none;
        padding: 0 0 $spacing-mobile-text-s;
      }
    }
  }

  .ft-list-item-expand-container {
    width: 100%;
    height: 100%;
  }

  .ft-list-item-expand {
    padding: $spacing-text-l 0;
    border-bottom: solid 1px $FT_limestone;
    @include mobile {
      padding: $spacing-mobile-text-l 0;
    }
  }

  .disruption-title {
    margin-bottom: $spacing-text-m;
  }

  .disruption-affected-stations {
    p {
      @include FT_body;
    }
    .disruption-affected-station {
      display: block;
      margin-bottom: $spacing-text-l;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .service-disruptions-item-header {
    border-bottom: solid 1px $FT_limestone;
    .service-disruptions-title,
    .service-disruptions-fromtime,
    .service-disruptions-totime {
      font-weight: 700;
    }

    .service-disruptions-time {
      @include mobile {
        display: none;
      }
    }
  }
}
