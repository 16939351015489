.footer-text-block {
  margin: 0 0 $spacing-block-xs;
  @include mobtablet {
    margin: 0 0 $spacing-mobile-block-m;
  }

  &__heading,
  p {
    color: $FT_footer;
    display: block;
  }

  &__heading{
    @include FT_contextual;
    text-transform: uppercase;
    margin: 0 0 $spacing-text-m;
    @include mobtablet {
      margin: 0 0 $spacing-mobile-text-m;
    }
  }
  p {
    font-size: 16px;
    line-height: 1.5;
  }
}
