.toggle-menu-button {
    $self: &;
    position: fixed;
    top: 17px;
    right: 22px;
    z-index: 2000;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    color: $FT_white;
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: none;
    font-family: $FT_font_family;

    @media (min-width: #{$screen-lg}) {
        top: 46px;
        right: 45px;
    }

    &__text {
        align-self: center;
        margin-right: 6px;
        font-size: 16px;
        line-height: 1;
        display: none;
        font-weight: 300;

        @media (min-width: #{$screen-lg}) {
            font-size: 25px;
            margin-right: 20px;
            display: block;

            #{$self}_active & {
                margin-right: 28.5px;
            }
        }

        #{$self}_active & {
            display: block;
        }
    }

    &__close-icon,
    &__open-icon {
        align-self: center;
        display: block;
        height: 15px;
        fill: $FT_white;

        @media (min-width: #{$screen-lg}) {
            height: 30px;
        }
    }

    &__close-icon {
        height: 12px;
        @media (min-width: #{$screen-lg}) {
            height: 30px;
            width: 30px;
        }
    }
}
