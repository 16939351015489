.gridded-block {
  background: $FT_shale;
  padding: $spacing-block-tmp 0;

  .block-title {
    margin-bottom: $spacing-block-tmp;
  }

  @include mobtablet {
    padding: $spacing-mobile-block-l 0;

    .block-title {
      margin-bottom: $spacing-mobile-block-m;
    }
    .blocks-container {
      .col-sm-4 {
        margin-bottom: $spacing-mobile-block-m;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.block-in-grid {
  a {
    display: flex;
    flex-flow: column;
    width: 100%;
    &:hover {
      h4 {
        text-decoration: underline;
      }
    }
  }

  .contextual-text {
    margin-top: $spacing-text-l;
    &.inverted {
      color: $FT_limestone_light;
    }
  }
  h4 {
    margin-top: $spacing-text-s;
  }

  @include mobile {
    display: flex;
    a {
      flex-flow: row;
    }
    .image-container {
      width: 33%;
      // The reason for this weird spacing is to get it aligned
      // with the contextual text (/date) and I didn't want to create
      // a variable just for this property.
      margin-top: 4px;
      margin-right: $spacing-mobile-text-l;
    }

    .block-in-grid-content .contextual-text {
      margin-top: 0;
      margin-bottom: $spacing-mobile-text-m;
    }
    h4 {
      margin-top: $spacing-mobile-text-m;
    }
    .block-in-grid-content {
      flex: 1;
    }
  }
}
