$animation-duration: 2s;

@mixin generateHorizontalMovement($steps, $stepsValues, $animationName) {
  $stepPercentage: 100% / ($steps - 1);
  $percentage: 0;

  @keyframes #{$animationName} {
    @for $i from 0 through $steps - 1 {
      #{$percentage} {
        left: #{nth($stepsValues, $i + 1)}px;
      }
      $percentage: $percentage + $stepPercentage;
    }
  }
}

$cxValues1: (0, 0, 0, 0, 0, 12, 40, 75, 115, 161, 191, 191, 161, 115, 75, 40, 12, 0, 0, 0, 0, 0);
$cxValues2: (0, 0, 0, 6, 13, 27, 80, 117, 155, 181, 197, 197, 181, 155, 117, 80, 27, 13, 6, 0, 0, 0);
$cxValues3: (0, 0, 0, 17, 25, 45, 120, 144, 173, 192, 197, 197, 192, 173, 144, 120, 45, 25, 17, 0, 0, 0);
$cxValues4: (0, 0, 12, 31, 45, 73, 152, 167, 187, 197, 197, 197, 197, 187, 167, 152, 73, 45, 31, 12, 0, 0);
$cxValues5: (0, 8, 24, 52, 73, 121, 173, 183, 197, 197, 197, 197, 197, 197, 183, 173, 121, 73, 52, 24, 8, 0);
$cxValues6: (0, 19, 43, 77, 113, 164, 186, 194, 197, 197, 197, 197, 197, 197, 194, 186, 164, 113, 77, 43, 19, 0);
$cxValues7: (8, 35, 71, 112, 158, 197, 197, 197, 197, 197, 197, 197, 197, 197, 197, 197, 197, 158, 112, 71, 35, 8);

@include generateHorizontalMovement(22, $cxValues1, animation1);
@include generateHorizontalMovement(22, $cxValues2, animation2);
@include generateHorizontalMovement(22, $cxValues3, animation3);
@include generateHorizontalMovement(22, $cxValues4, animation4);
@include generateHorizontalMovement(22, $cxValues5, animation5);
@include generateHorizontalMovement(22, $cxValues6, animation6);
@include generateHorizontalMovement(22, $cxValues7, animation7);

.payment-spinner-animation{
  position: relative;
  display: flex;
  width: 200px;
  height: 3px;

  &__circle {
    background: #FF551E;
    width: 6px;
    height: 6px;
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
  }
  &__circle1{
    animation: animation1 $animation-duration infinite alternate;
  }
  &__circle2{
    animation: animation2 $animation-duration infinite alternate;
  }
  &__circle3{
    animation: animation3 $animation-duration infinite alternate;
  }
  &__circle4{
    animation: animation4 $animation-duration infinite alternate;
  }
  &__circle5{
    animation: animation5 $animation-duration infinite alternate;
  }
  &__circle6{
    animation: animation6 $animation-duration infinite alternate;
  }
  &__circle7{
    animation: animation7 $animation-duration infinite alternate;
  }
}
