.language-selector {
    min-height: $headerHeightMobile;
    padding-right: 70px;
    padding-top: 12px;

    @media (min-width: #{$screen-lg}) {
        min-height: $headerHeightDesktop;
        padding-top: 46px;
        padding-right: 130px;
    }

    &__item{
        display: inline-flex;
        margin: 0 10px 10px 0;

        @media (min-width: #{$screen-lg}) {
            padding-top: 3px;
            margin: 0 15px 15px 0;
        }
    }
}
