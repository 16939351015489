@keyframes bounce_dark {
    0% {
        background-color: $FT_black;
        transform: scale(1);
    }

    100% {
        background-color: $FT_white;
        transform: scale(0.3);
    }
}

@keyframes bounce_light {
    0% {
        background-color: $FT_white;
        transform: scale(1);
    }

    100% {
        background-color: $FT_anthracite;
        transform: scale(0.3);
    }
}

.spinner {
    $self: &;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 80px;

    &__item {
        display: inline-block;
        width: 4px;
        height: 4px;
        margin: 0 2px;
        animation-duration: 0.975s;
        animation-iteration-count: infinite;
        animation-direction: normal;
        border-radius: 3px;
        transform: scale(0.3);

        &:nth-child(1) {
            animation-delay: 0.386s;
        }

        &:nth-child(2) {
            animation-delay: 0.4825s;
        }

        &:nth-child(3) {
            animation-delay: 0.589s;
        }

        &:nth-child(4) {
            animation-delay: 0.6855s;
        }

        &:nth-child(5) {
            animation-delay: 0.782s;
        }

        &:nth-child(6) {
            animation-delay: 0.8785s;
        }

        &:nth-child(7) {
            animation-delay: 0.975s;
        }

        &:nth-child(8) {
            animation-delay: 1.0715s;
        }

        #{$self}--dark & {
            background-color: $FT_white;
            animation-name: bounce_dark;
        }
        #{$self}--light & {
            background-color: $FT_anthracite;
            animation-name: bounce_light;
        }
    }
}
