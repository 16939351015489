@import "../menu-link/menu-link";
.menu-item {
    $self: &;

    &__toggle-button {
        @extend .menu-link;

        #{$self}_secondary & {
            @extend .menu-link_secondary;
        }
    }

    &__toggle-button {
        @include button-clean;
        transition: margin-bottom 0.5s ease-in-out;

        &-icon {
            display: inline-flex;
            width: 16px;
            margin: 7px 0 0 9px;
            transition: all 0.5s ease-in-out;

            #{$self}_secondary & {
                margin-top: 6px;
                width: 12px;
                margin-left: 8px;
            }

            @media (min-width: #{$screen-lg}) {
                width: 21px;
                margin-left: 16px;
                margin-top: 12px;

                #{$self}_secondary & {
                    width: 14px;
                    margin-left: 17px;
                    margin-top: 12px;

                    @media (min-width: #{$screen-lg}) {
                        margin-top: 9px;
                    }
                }
            }

            #{$self}__toggle-button_opened &{
                transform: rotate(-180deg);
            }
        }

        &-text {
            #{$self}__toggle-button_active &{
                text-decoration: underline;
            }
        }
    }

    #{$self}__toggle-button_opened {
        @media (min-width: #{$screen-lg}) {
            margin-bottom: 20px;
        }
    }

    &_secondary #{$self}__toggle-button_opened {
        @media (min-width: #{$screen-lg}) {
            margin-bottom: 20px !important;
        }
    }

    &__second-level-items {
        display: flex;
        flex-direction: column;
        padding-bottom: 13px;
    }
}
