.rich-text-block {
  margin: $spacing-block-s 0;
  .ingress {
    margin-bottom: 80px;
  }

  .editorial-text {
    > *:first-child {
      margin-top: 0;
    }
  }

  @include mobtablet {
    margin: $spacing-mobile-block-l 0;
  }
}
