@import "../../shared-components-styles/travel-and-shop-mixins.scss";
.shopping-cart-response-container {
  @include travel-and-shop-container;
  padding-top: 0;
}

.shopping-cart-response {
  width: 650px;
  @include mobile {
    width: 100%;
    max-width: 650px;
  }
}

.shopping-cart-response-inner {
  position: relative;
  width: 650px;
  margin: 0 auto;

  @include mobile {
    margin: auto;
    max-width: 650px;
    width: calc(100% - 20px);
  }
  .row.travel-inputs {
    border-bottom: solid 1px $FT_limestone;
    margin-bottom: 1rem;
  }
  .travel-input {
    @include tabletdesktop {
      max-width: calc(50% - 25px);
    }
  }
  background-color: $FT_white;
}

.response-content {
  padding: 30px 35px;
  text-align: center;
  a {
    text-decoration: underline;
    color: $FT_anthracite;
  }
  > h2 {
    font-family: $FT_font_family;
    font-weight: 300;
    font-size: 34px;
    color: $FT_black;
    line-height: 30px;
    margin: 20px 0;

    @include mobile {
      font-size: 24px;
    }
  }
}

.details-wrapper {
  text-align: center;
  margin: 20px auto;
  border: none;
  outline: 0;

  td {
    margin: 20px 0;
    padding: 0 5px;
    vertical-align: top;
  }
  td:first-child {
    text-align: left;
    padding-right: 30px;
  }
  td:last-child {
    text-align: right;
    padding-left: 30px;
  }
}

.help-text {
  margin-top: 30px;
}
