.file-list-block-container {
  padding: $spacing-block-tmp 0;

  .file-list-block-title {
    margin-bottom: $spacing-block-xs;
  }
  .file-item-container {
    &:hover {
      .file-name {
        border-bottom: solid 1px $FT_anthracite;
        color: $FT_anthracite;
      }
    }
  }
  .file-item-title {
    margin-bottom: $spacing-text-s;
  }
  .file-name {
    @include FT_H4;
    text-decoration: none;
    color: $FT_orange_link;
    word-break: break-word;
  }

  .table-row {
    > div:first-child {
      flex: 1;
    }
    .file-type {
      width: 90px;
    }
    .file-size {
      width: 120px;
    }

    @include mobile {
      > div:first-child {
        flex: 1 100%;
      }
    }
  }

  @include mobtablet {
    padding: $spacing-mobile-block-l 0;
    .file-list-block-title {
      margin-bottom: $spacing-mobile-block-m;
    }
  }
}
