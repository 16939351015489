.travel-results-details-outer {
  flex: 1;
  display: flex;
  flex-direction: column;
  .date-label {
    cursor: default;
    &:hover {
      text-decoration: none !important;
    }
  }
}

.travel-results-details-container {
  background: white;
  flex: 1 0 0;
  overflow: visible;
  max-height: none;
}

$time-width: 59px;

.details-header {
  background: $FT_limestone;
  padding: 10px;
  z-index: 1;
  font-weight: 400;
  &.invalid {
    background: $FT_glow_light;
  }
}

// general stop
.details-stop {
  display: flex;
  > * {
    padding: 20px 0;
  }
  font-size: 1em;

  &.in-past{
    background: $FT_past-travel-results_bg;
  }
  
  .details-name {
    flex: 1 0 0;
    text-align: left;
    span {
      background: none !important;
      padding-left: 10px;
      font-size: 0.7em;
    }
  }
  .details-time {
    width: $time-width;
    margin-left: $site-column-gutter-travel-planner;
    text-align: right;
    @include mobile {
      margin-left: $site-column-gutter-mobile-travel-planner;
    }
  }
  .details-gfx {
    width: 59px;
  }

  .details-gfx {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      &:before {
        content: " ";
        position: absolute;
        top: 50%;
        left: 26px;
        margin-top: -3px;
        width: 7px;
        height: 7px;
        background: black;
        border: solid 1px black;
        border-radius: 100%;
      }
      &::after {
        content: " ";
        border-left: solid 1px $FT_black;
        position: absolute;
        top: calc(50% + 4px);
        bottom: calc(-50% + 3px);
        left: 29px;
      }
    }
  }

  &.to-last {
    .to-airport & {
      .details-gfx span::after {
        bottom: calc(-50% + 10px);
      }
    }
  }

  &.first,
  &.last {
    font-size: 1.2em;
    .details-gfx {
      span {
        &:before {
          background: white;
          width: 9px;
          height: 9px;
          left: 25px;
          margin-top: -4px;
        }
        &::after {
          top: calc(50% + 5px);
        }
      }
      svg {
        display: block;
        width: 32px;
        height: 32px;
        margin-top: -5px;
      }
      svg + span {
        &:before {
          content: none;
        }
        &::after {
          top: calc(50% + 10px);
        }
      }
    }
  }

  &.first {
    svg + span {
      &::after {
        top: calc(50% + 10px);
      }
    }
  }

  &.last,
  &:last-child .last &,
  &:first-child .last & {
    .details-gfx {
      span::after {
        display: none;
      }
    }
  }

  &.cancelled {
    text-decoration: line-through;
    .details-gfx {
      svg {
        display: none;
      }
      span {
        &::after {
          background: none;
          border-left: none;
        }
        &::before {
          content: none;
        }
      }
    }
  }

  &.cancelled:not(.to-cancelled) {
    .details-gfx span::after {
      background: none;
      border-left: none;
      border-bottom: solid 2px $FT_black;
      width: 19px;
      margin-left: -9px;
      bottom: calc(-50% + 0.19em);
    }
  }

  &.to-cancelled:not(.cancelled) {
    .details-gfx span::after {
      border-left: none;
      border-top: solid 2px $FT_black;
      width: 19px;
      margin-left: -9px;
    }
  }
}

.details-deviation {
  position: relative;
  margin: 0 30px;
  @include mobile {
    margin: 0 15px;
  }
  .details-name {
    padding-right: 20px;
  }
  &.first {
    margin-top: 30px;
  }
  &.last {
    margin-bottom: 30px;
    .details-stop-list {
      .details-stop:last-child .details-gfx {
        span,
        &::after {
          display: block;
        }
      }
    }
    .details-stop:last-child .details-gfx {
      &::after {
        display: none;
      }
      svg {
        display: block;
        width: 32px;
        height: 32px;
        margin-top: -5px;
      }
    }
  }
  &.to-switch {
    > .details-stop:last-child {
      .details-gfx span::after {
        bottom: 0;
        background: #fff;
        border: none;
        border-left: solid 1px $FT_limestone;
      }
    }
  }
  &.deviation-switch {
    .details-walk {
      margin-bottom: 30px;
    }
    &:not(.to-other).to-last {
      .details-walk {
        margin-bottom: 0;
      }
    }
  }
  &.deviation-walk {
    .details-stop:first-child {
      .details-gfx span::after {
        bottom: 0;
        background: none;
        border: none;
        border-left: solid 1px $FT_limestone;
      }
    }
  }
  &.deviation-other {
    background: $FT_glow_light;
    border-radius: 5px;
    margin-bottom: 30px;
    .details-stop-list {
      display: none;
      &.visible {
        display: block;
      }
      .details-stop:last-child .details-gfx {
        span::after {
          content: " ";
        }
        &::after {
          content: none;
        }
      }
    }
    .details-stop {
      span {
        &::before {
          background: $FT_glow;
          border-color: $FT_glow;
        }
        &::after {
          border-left-color: $FT_glow;
        }
      }
      &:first-child {
        span {
          &::after {
            top: 30px;
            bottom: -30px;
          }
        }
      }
      &:last-child .details-gfx {
        span::after {
          content: none;
        }
        &::after {
          content: " ";
          background: #fff;
          border: none;
          border-left: solid 1px $FT_limestone;
          width: 1px;
          position: absolute;
          top: 100%;
          bottom: -60px;
          left: 29px;
          .to-walk & {
            bottom: -30px;
          }
        }
      }
    }
  }
}

.details-other,
.details-walk {
  display: flex;
  margin: 0 0 30px ($time-width + $site-column-gutter-travel-planner);
  @include mobile {
    margin: 0 0 30px ($time-width + $site-column-gutter-mobile-travel-planner);
  }
  > * {
    padding: 20px 0;
  }
  font-size: 0.9em;
  .details-gfx {
    width: 59px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      content: " ";
      background: #fff;
      border: none;
      border-left: solid 1px $FT_limestone;
      width: 1px;
      position: absolute;
      top: 100%;
      bottom: -60px;
      left: 29px;
      .to-other &,
      .to-walk & {
        bottom: -30px;
      }
    }
    svg {
      display: block;
      width: 28px;
      height: 28px;
    }
  }

  .details-content {
    flex: 1 0 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    a {
      color: $FT_orange_link;
      font-weight: 400;
      svg {
        width: 10px;
        height: 10px;
        transform: rotate(90deg);
        fill: $FT_orange_link;
        margin-left: 5px;
      }
    }
    button {
      padding: 0;
      margin-top: 5px;
      color: $FT_grey;
      align-self: flex-start;
      font-size: 0.8em;
      display: flex;
      align-items: center;
      padding: 5px 5px 5px 0;
      svg {
        display: inline-block;
        width: 0.8em;
        height: 0.8em;
        margin-left: 10px;
        transform: rotate(180deg);
        transform-origin: 50% 50%;
        transition: transform 0.3s ease-in-out;
      }
      .hidden {
        display: inline-block;
      }
      .visible {
        display: none;
      }
      &.visible {
        .hidden {
          display: none;
        }
        .visible {
          display: inline-block;
        }
        svg {
          transform: rotate(0deg);
        }
      }
    }
  }
}

// walk deviation details
.details-walk {
  border-radius: 5px;
  background: $FT_limestone;
  width: 40%;
  @include mobile {
    width: auto;
  }
  &.to-normal {
    margin-bottom: 0;
    .details-gfx {
      &::after {
        bottom: -30px;
      }
    }
  }

  .details-content {
    > *:first-child + * {
      margin: 10px 0 0 0;
    }
  }
}

// other deviation details
.details-other {
  margin-bottom: 0;
  .details-gfx {
    &::before {
      display: block;
      background: $FT_glow;
      content: "!";
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-weight: bold;
      font-size: 1.1em;
      margin-left: 1px; // optical center
      position: static;
      border-radius: 100%;
      z-index: 1;
    }
    &::after {
      border-left-color: $FT_glow;
      border-left-style: solid;
      border-left-width: 1px;
      width: 0;
      top: 0;
      bottom: -33%;
      padding-top: calc(100% - 15px);
    }
  }
  .details-content {
    padding-right: 20px;
    font-weight: 400;
  }
}
