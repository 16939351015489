// Styling related to the article page.
.article-container {
  &.is-slim {
    .article-main-content {
      // @todo: Do we need this ?
      //margin-top: $spacing-block-s;
    }
    .media-content {
      margin-bottom: 0;
    }
    @include mobtablet {
      .publish-date.contextual-text {
        margin-top: $spacing-text-xl;
        margin-bottom: $spacing-text-m;
      }
    }
    @include mobile {
      .publish-date.contextual-text {
        margin-top: $spacing-text-xl;
      }
      .article-main-content {
        margin-top: $spacing-mobile-block-m;
      }
    }
  }
  .section-single-img-full {
    height: 550px;
    overflow: hidden;
  }
  .main-title {
    margin-bottom: $spacing-block-tmp;
  }

  .media-content {
    margin-bottom: $spacing-block-tmp;

    .media-content-block{
      @include image-max-width;
    }

    &.is-wide {
      .fluid-image-information-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: $site-lg-width;
        margin: 0 auto;
      }
      .fluid-image-information {
        flex-basis: get-col-percent(12);
        max-width: get-col-percent(12);
        padding-left: $site-column-gutter;
        padding-right: $site-column-gutter;
        @media (min-width: 768px) {
          margin-left: get-col-percent(4);
          flex-basis: get-col-percent(8);
          max-width: get-col-percent(8);
        }
      }
      .media-content-block {
        .fluid-image {
          height: 540px;
          @include mobtablet {
            height: 320px;
          }
        }
      }
    }

    .media-content-block {
      margin-bottom: $spacing-text-l;
      &:last-child {
        margin-bottom: 0;
      }
      .fluid-image {
        height: 440px;
        @include mobtablet {
          height: 320px;
        }
      }
    }
  }
  .article-main-content {
    .ingress {
      //margin-bottom: $spacing-block-xs;
      margin-bottom: 0;
    }
    .contextual-text {
      color: $FT_shale;
      margin-bottom: $spacing-text-m;
    }
  }

  .contextual-text {
    color: $FT_shale;
    margin-bottom: $spacing-text-l;
  }

  .article-content {
    .editorial-text > *:first-child,
    .editorial-text > *:first-child > *:first-child {
      margin-top: 0;
    }
  }

  @include mobtablet {
    .section-single-img-full {
      height: auto;
      overflow: hidden;
    }
    .main-title {
      margin-bottom: $spacing-mobile-block-s;
    }

    .media-content {
      margin-bottom: $spacing-mobile-block-m;
    }

    .article-main-content {
      .contextual-text {
        color: $FT_shale;
        margin-bottom: $spacing-mobile-text-m;
      }
    }
    .article-content {
      margin-bottom: $spacing-mobile-block-l;
    }
  }
}

.article-main {
  margin-top: $spacing-text-xl;
  margin-bottom: $spacing-text-xl;
  @include mobtablet {
    margin-top: #{$spacing-mobile-text-l * 2};
    margin-bottom: $spacing-mobile-text-l;
  }
}

.article-blocks {
  margin: $spacing-block-tmp 0;
  @include mobtablet {
    margin: $spacing-mobile-block-m 0;
  }
}

.article-sharing {
  margin: $spacing-block-xs 0;
  color: $FT_shale;
  overflow: hidden;
  .icon {
    margin-left: $spacing-text-s;
  }
  .icon-print {
    fill: $FT_shale;
    &:hover {
      fill: $FT_orange_link;
    }
  }
}

.fluid-image-description,
.fluid-image-photographer {
  color: $FT_grey;
}

.article-sharing-info {
  display: flex;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
  }
}

.article-main-content {
  overflow: hidden;
}
