.error-block {
  position: relative;
  line-height: 24px;
  font-size: 15px;
  overflow: hidden;

  width: 100%;
  margin: 0 auto 35px;
  @include mobile {
    margin: 10px 10px 35px;
  }

  .shopping-cart-container & {
    &.row {
      margin: 0 (-$site-column-gutter) 0.5rem;
      @include mobile {
        margin: 0 (-$spacing-block-xxxs) 0.2rem;
      }
    }
  }
  .stepper-row-wrapper & {
    margin: 0 -35px;
    width: auto;
    @include mobile {
      margin: 0 -15px;
    }
  }
  @include mobile {
    font-size: 12px;
  }

  .icon {
    width: 3em;
    &:before {
      display: block;
      content: "!";
      background: $FT_white;
      border-radius: 100%;
      width: 1.5em;
      height: 1.5em;
      margin: 0 10px;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5em;

      @include mobile {
        margin: 0 5px;
      }
    }
  }

  .header {
    width: 100%;
    display: flex;
    padding: ($spacing-text-s - 1) 0;
  }

  .type-ok {
    background: $FT_dev_green;
    color: $FT_dev_green_text;
    .icon:before {
      background: $FT_dev_green_dark;
      color: $FT_white;
    }
  }
  .type-warning {
    background: $FT_dev_yellow;
    color: $FT_dev_yellow_text;
    .icon:before {
      background: $FT_dev_yellow_dark;
      color: $FT_dev_green_text; // yes, green is correct here
    }
  }
  .type-error {
    background: $FT_dev_red;
    color: $FT_white;
    .icon:before {
      background: $FT_dev_red_dark;
    }
  }

  .title {
    flex: 1;
    transition: all 0.2s ease-in;
    display: flex;
    align-items: center;
    padding: 0 $spacing-text-xl 0 0;
    font-family: inherit;
    // white-space: pre-line;
  }
  .description {
    padding: ($spacing-text-s - 1) 0;
    text-align: center;
    background: $FT_white;
    // white-space: pre-line;
  }
}
