.superpage-container {
  &.is-slim {
    .main-title {
      margin: $spacing-block-xs 0 ($spacing-block-tmp / 2);
    }
    .media-content {
      .fluid-image {
        height: 540px;
      }
    }
  }
  .media-content {
    position: relative;
    .fluid-image {
      height: 750px;
    }
    /* It was decided that image description should not be shown in the media content of a superpage. */
    .fluid-image-information-container {
      display: none;
    }
  }
  .promo-gradient {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background: $FT_black;
    //background: linear-gradient(to bottom, rgba(9,92,105,1) 0%,rgba(143,22,57,1) 100%);
    /*z-index: 10; */
  }
  .main-title-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .main-title {
      margin-bottom: 0;
      z-index: 10;
    }
  }

  @include mobtablet {
    &.is-slim {
      .main-title {
        margin: $spacing-mobile-block-s 0 $spacing-mobile-block-s;
      }
      .media-content {
        .fluid-image {
          height: 200px;
        }
      }
    }
    .media-content {
      .fluid-image {
        height: 250px;
      }
    }
  }

  .publish-date {
    color: $FT_shale;
    margin: $spacing-text-l 0 0;
  }
}
