.stepper-row-wrapper {
  background-color: $FT_white;
  padding: 0 $site-column-gutter $site-column-gutter $site-column-gutter;

  @include mobile {
    padding: 0 $spacing-block-xxxs $spacing-block-xxxs $spacing-block-xxxs;
  }

  .stepper-row-more-fares {
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    will-change: transform;
  }

  .stepper-row-show-more {
    padding: 0;
    font-family: $FT_font_family;
    font-size: 25px;
    @include mobile {
      font-size: inherit;
    }
    color: $FT_anthracite;
    font-weight: 300;
    line-height: 14px;

    display: inline-block;
    margin: 20px 0;
    cursor: pointer;
    will-change: transform;
    span {
      transition: transform 0.3s ease-in-out;
      display: inline-block;
      line-height: 1;
      width: 0.8em;
      height: 1em;
      transform: translate(0, 0.1em) rotate(180deg);
      margin-left: 0.3em;
    }
    &.open span {
      transform: translate(0, -0.1em) rotate(0deg);
    }
  }

  .stepper-row-link {
    color: inherit;
    text-decoration: underline;

    @include mobile {
      font-size: 14px;
    }
  }
}
