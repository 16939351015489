$height-dsk: 47px;
$height-mob: 33px;
.travel-planner .travel-inputs {
  background: $FT_white;
  position: relative;
  z-index: 102;
  flex-wrap: nowrap;
  @include mobile {
    flex-wrap: wrap;
    &:after {
      content: "";
      width: 100%;
    }
  }
}
.travel-input {
  background: $FT_white;
  font-size: 25px;
  font-weight: 300;
  padding-top: $spacing-text-m;
  padding-bottom: $spacing-text-m;
  margin: 0;
  display: flex;
  vertical-align: middle;
  justify-content: center;

  @include mobile {
    font-size: 15px;
    padding-top: 0;
    padding-bottom: $spacing-text-s;
  }

  &.travel-input-from {
    flex: 1 0 41.66%;
    @include mobile {
      flex-basis: 50%;
    }
    > .Select > .Select-control {
      padding-right: 0;
    }
  }
  &.travel-input-to {
    flex: 1 0 41.66%;
    text-align: right;
    @include mobile {
      flex-basis: 50%;
      > .Select > .Select-control {
        padding-left: 0;
      }
    }
    > .Select {
      > .Select-control {
        justify-content: flex-end;
        padding-left: 0;
      }
      text-align: left;
    }
  }

  &.travel-input-center {
    align-items: center;
    position: relative;
    padding-left: $spacing-text-s/2;
    padding-right: $spacing-text-s/2;
    width: 50px;
    @include mobile {
      padding-top: 0;
      padding-bottom: 0;
      order: -1;
      width: 100%;
      height: 20px;
      flex: none;
    }
  }
}

.button-switch {
  display: flex;
  align-items: center;
  padding: 0 5px;
  border: none;
  background: transparent;
  height: $height-dsk;
  margin: 0;
  cursor: pointer;
  outline: none;
  width: 100%;
  @include mobile {
    width: auto;
    z-index: 102;
    position: absolute;
    height: 40px;
    top: -20px;
    left: 50%;
    margin-left: -20px;
    &:after {
      content: " ";
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -20px;
      margin-top: -20px;
      background: $FT_limestone_light;
      border-radius: 100%;
      height: 40px;
      width: 40px;
    }
  }
  svg {
    position: relative;
    z-index: 1;
    width: 100%;
    min-width: 23px;
    max-width: 31px;
    height: 30px;
    @include mobile {
      margin-top: 2px;
      max-width: 23px;
      height: 33px;
    }
    fill: $FT_anthracite;
  }
  &:hover,
  &:focus {
    .no-touch & {
      svg {
        fill: $FT_orange;
      }
    }
  }
}

$padding-size: 20px;

.Select {
  margin: 0 ($site-column-gutter-travel-planner/2);
  transition: color 0.2s ease-in-out, fill 0.2s ease-in-out;
  @include mobile {
    margin: 0 (-$site-column-gutter-mobile-travel-planner);
  }
  width: 100%;
  line-height: 1.75;

  > .Select-control .Select-value .Select-value-label {
    color: inherit;
  }
  > .Select-control {
    top: -1px;
    padding: 0 ($site-column-gutter-travel-planner/2);
    @include mobile {
      padding: 0 ($site-column-gutter-mobile-travel-planner/2);
    }
    transition: color 0.2s ease-in-out, fill 0.2s ease-in-out;
    &:hover,
    &:focus {
      .no-touch & {
        .Select-value {
          border-bottom: solid 1px #b6b6b6;
        }
      }
    }
  }
  &.is-focused > .Select-control {
    .Select-value {
      border-bottom: solid 1px #b6b6b6;
    }
  }

  .Select-arrow-zone {
    padding: 0;
    line-height: inherit;
    width: 18px;
    max-width: 18px; // ie11 - contain svg
    max-height: 55px;
    @include mobile {
      line-height: 23px;
      width: 10px;
    }
  }
  .Select-input > input {
    padding: 0;
  }
  .Select-arrow-zone svg {
    transition: all 0.2s ease-in-out;
    transform-origin: 50% 50%;
    transform: rotate(180deg);
    max-height: 55px;
    @include mobile {
      max-height: 30px;
    }
  }

  .Select-control,
  .Select-menu-outer {
    border: none;
    box-shadow: none;
  }

  &.is-open {
    z-index: 1200;
    @include mobile {
      position: fixed;
      top: 50px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      padding: 0;
      border-radius: 0;
      z-index: 9999;
      .Select-control {
        padding: 15px 20px;
      }
      .Select-menu {
        overflow-y: auto;
      }
    }
    color: $FT_anthracite;
    background: $FT_limestone_light;
    .Select-control {
      background: transparent;
      z-index: 1301;
      .Select-value {
        border-bottom: none !important;
      }
    }
    .Select-menu-outer {
      background: inherit;
      max-height: 65vh;
      overflow-y: auto;
      @include mobile {
        .Select-option {
          padding-left: $spacing-text-xl;
          padding-right: $spacing-text-m;
        }
        font-size: 16px;
        top: 60px;
        bottom: 0;
        max-height: none;
        border: none;
        border-radius: 0;
        z-index: 1401;
      }
    }
    .Select-menu {
      background: transparent;
    }
    .Select-option {
      background: transparent;
      color: inherit;
      padding-left: $site-column-gutter-travel-planner;
      padding-right: $site-column-gutter-travel-planner;
      @include mobile {
        padding-left: 0;
        padding-right: 0;
      }
      &.is-selected {
        &:hover,
        &.is-focused {
          svg {
            fill: #fff;
          }
        }
        svg {
          height: 12px;
          width: 16px;
          margin-left: -29px;
          margin-right: 12px;
        }
      }
      &.is-focused {
        background: $FT_orange_link;
        color: $FT_white;
      }
    }
    .Select-arrow-zone svg {
      transform: none;
    }
  }
}

.Select-control,
.Select-input {
  color: inherit;
  background: transparent;
  height: $height-dsk;
  @include mobile {
    height: $height-mob;
  }
}
.Select-placeholder,
.Select-input > input,
.Select > .Select-control .Select-value {
  padding-right: $padding-size;
  color: inherit;
  line-height: $height-dsk;
  @include mobile {
    line-height: $height-mob;
  }
}
.Select-option {
  font-size: 1.25rem;
  padding: 6px 0;
}

.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  .Select-value-label {
  color: inherit;
}

.Select-menu,
.Select-menu-outer {
  max-height: none;
}
.Select-menu-outer {
  left: 0;
}
.Select-control:hover {
  box-shadow: none;
}

.Select > .Select-control .Select-value {
  position: inherit;
  float: left;
  display: block;
  padding-right: 0;
  line-height: inherit;
}
.Select > .Select-control .Select-input {
  position: absolute;
}
.Select > .Select-control {
  display: flex;
  justify-content: flex-start;
  border-spacing: inherit;
  border-collapse: inherit;
  overflow: inherit;
  cursor: pointer;
}
.Select-multi-value-wrapper {
  display: block;
  float: left;
  max-width: 90%;
  padding-right: $spacing-text-s;
}
.Select-arrow-zone {
  display: block;
  float: left;
}

.Select.is-focused {
  .Select-control {
    *:focus {
      box-shadow: none;
    }
  }
}
