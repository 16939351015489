.form-wrapper {
  width: 300px;
  margin: 0 auto;
  margin-bottom: 35px;
  @include mobile {
    width: 100%;
    padding: 0 15px;
  }
  .checkbox-container {
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
  }
  .mobile-phone,
  .email {
    select::-ms-expand {
      display: none;
    }
    select,
    input {
      width: 100%;
      height: 40px;
      padding: 8px 14px;
      display: block;
      border: 1px solid $FT_orange_link;
      color: $FT_orange_link;
      line-height: 25px;
      font-size: 16px;
      line-height: 25px;
      font-weight: 300;
      &.error {
        border-color: $FT_firebrick;
        border-width: 2px;
      }
      &::placeholder {
        font-style: italic;
        color: $FT_orange_link;
      }
    }
    label:not(.custom-select) {
      line-height: 1.2;
      display: block;
      margin-top: 0.5rem;
    }
    .container {
      display: flex;
      select {
        width: auto;
        border-radius: 0;
        margin-right: 1rem;
      }
      input {
        flex: 1;
      }
    }
  }
  .email {
    margin-top: $spacing-text-m;
  }
  .error-container {
    .form-error-message {
      margin-bottom: 20px;
    }
  }
  .checkbox-container {
    > span {
      margin-left: 35px;
      display: block;
      &.form-error-message {
        margin-left: 0;
      }
    }
  }
  .checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin: 18px 0;
    cursor: pointer;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &:active + span,
      &:focus + span {
        outline-width: 3px;
        outline-style: solid;
        outline-color: Highlight;
        @media (-webkit-min-device-pixel-ratio: 0) {
          outline-color: -webkit-focus-ring-color;
          outline-style: auto;
        }
      }
      &.error + span {
        border-color: $FT_firebrick;
        border-width: 2px;
      }
    }
    input:checked ~ .checkmark:after {
      display: block;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      border: 1px solid #979797;
      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 3px;
        width: 7px;
        height: 14px;
        border: solid $FT_orange_link;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
  .payment-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    margin: 35px 0 0;
    div {
      margin: 0 auto 10px;
      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .submit-wrapper {
    margin: 25px 0 0;
    width: 100%;
    text-align: center;
  }
  .submit {
    background: $FT_orange;
    border-radius: 100px;
    width: 230px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    color: $FT_white;
    line-height: 18px;
    letter-spacing: 1px;
    cursor: pointer;
    @include mobile {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.form-error-message {
  display: block;
  font-size: 15px;
  color: $FT_firebrick;
  line-height: 18px;
  font-weight: 500;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  span {
    display: block;
    padding: 12px 14px 8px 0;
  }
}

.form-checkbox-link {
  text-decoration: underline;
  color: $FT_anthracite;
}

.shopping-cart-form.form-wrapper .checkbox-container > span{
  margin-left: 48px;
  margin-right: -50px;
  width: calc(100% + 50px);
  @include mobile {
    margin-left: 35px;
    margin-right: 0;
    width: calc(100% - 35px);
  }
}