//
// Basic Bootstrap table
//
.table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;

  th,
  td,
  .table-header,
  .table-cell {
    vertical-align: top;
    padding: 20px;
  }
  tbody tr,
  .table-row {
    transition: background 0.3s ease-out;
  }
  tbody tr:nth-of-type(odd),
  .table-row:nth-of-type(odd) {
    background-color: $FT_grey_light;
  }
}

.table-row {
  display: flex;
  flex-wrap: wrap;
}

.table-hover {
  tbody tr:hover,
  .table-row:hover {
    background-color: $FT_limestone_light;
  }
}

.table-responsive {
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
