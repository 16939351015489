.list-block-header {
  padding: $spacing-text-xl 0 $spacing-text-s;
  background: $FT_grey_light;
}

.pagination {
  background: $FT_white;
  padding: $spacing-block-xs 0;
  position: relative;
  display: flex;
  align-items: center;

  &.type-grey {
    background: $FT_grey_light;
  }

  .pagination-container {
    display: flex;
    align-items: center;
  }

  .pages-container {
    display: flex;
    flex-flow: row;
  }

  .pages {
    display: flex;
    flex-flow: row;
    position: relative;
    align-items: center;
    justify-content: center;
    flex: 1;

    .page-number {
      display: block;
      text-align: center;
      font-size: 30px;
      position: relative;
      font-weight: 100;
      color: $FT_black;
      margin: 0 $spacing-text-m;

      &:hover,
      &.is-active {
        font-weight: 400;
        color: $FT_shale;
      }

      @include mobtablet {
        font-size: 21px;
        font-weight: 300;

        text-decoration: none;
        color: $FT_orange_link;
        margin: 0 15px;
        &:hover,
        &.is-active {
          color: $FT_black;
          text-decoration: none;
        }
      }
    }
  }

  li {
    .page-number {
      display: block;
      text-align: center;
      font-size: 30px;
      position: relative;
      font-weight: 100;
      color: $FT_black;
      margin: 0 $spacing-text-m;
      cursor: pointer;

      &:hover,
      &.is-active {
        font-weight: 400;
        color: $FT_shale;
      }

      @include mobtablet {
        font-size: 21px;
        font-weight: 300;

        text-decoration: none;
        color: $FT_orange_link;
        margin: 0 15px;
        &:hover,
        &.is-active {
          color: $FT_black;
          text-decoration: none;
        }
      }
    }

    &.active {
      .page-number {
        font-weight: 400;
        color: $FT_shale;
      }
    }
  }

  .break-me {
    font-weight: 700;
  }

  .pagination-action,
  .active-image,
  .disabled-image {
    width: 40px;
    @include mobtablet {
      width: 20px;
    }
  }

  .has-next,
  .has-previous {
    cursor: pointer;
  }

  .active-image {
    display: none;
    &.has-previous,
    &.has-next {
      display: block;
    }
  }
  .disabled-image {
    display: block;
    &.has-previous,
    &.has-next {
      display: none;
    }
  }

  .rotated-image {
    transform: rotate(180deg);
  }
  a:focus > .active-image {
    outline: none;
    box-shadow: 0 0 3px 3px dodgerblue;
  }
}
