// Styles that (may) overwrite the default styling from intelecom chat.

.intelecomchatheader {
  background: $FT_anthracite;
}

.btn.chatbutton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: $FT_anthracite;
  padding: 10px 30px;
  border: 0;
  border-radius: 30px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  text-transform: none;
  color: $FT_limestone;
  text-indent: 0;
  height: 50px;
  display: flex;
  font-size: 18px;
  line-height: 21px;

  @media (min-width: #{$screen-lg}) {
    font-size: 25px;
    line-height: 39px;
    height: 60px;
    bottom: 82px;
    right: 60px;
  }

  &:hover {
    text-decoration: underline;
  }

  .icon-chat {
    width: 20px;
    margin-left: 10px;
    align-self: flex-end;

    @media (min-width: #{$screen-lg}) {
      width: 30px;
      margin-left: 13px;
    }
  }

  .icon-chat,
  .icon-chat > * {
    stroke: $FT_white;
    fill: $FT_white;
    color: $FT_white;
  }
}

// Chat bubble (me)
.intelecomdialog__question-text {
  background: #d5dee3;
  box-shadow: 0;
}
// Chat bubble arrow (me)
.intelecomdialog__question-arrow-shadow {
  background: #d5dee3;
  box-shadow: 0;
}

// Chat background (where the bubbles are)
.intelecomchatdialog {
  background: #eef1f3;
}

// Chat bubble (other)
.intelecomdialog__answer-arrow-shadow {
  box-shadow: 0;
}

// Chat time (me/other)
.intelecomdialog__answer-time,
.intelecomdialog__question-time {
  color: #303e48;
}
