.quote-block {
  background: $FT_slate;
  padding: $spacing-block-m 0;

  &.type-white {
    background: $FT_white;
    .quote {
      color: $FT_shale;
      font-weight: 300;
    }
  }

  .quote {
    text-align: center;
    margin: 40px 0;
  }

  .icon-quote {
    width: 40px;
    height: auto;
    fill: $FT_pebble;
  }

  @include mobile {
    padding: $spacing-mobile-block-l 0;

    .quote {
      margin: $spacing-mobile-block-s 0 $spacing-mobile-block-s;
    }
  }
}
