.contact-block {
  position: relative;
  overflow: hidden;
  text-overflow: clip ellipsis;

  h4 {
    margin-bottom: $spacing-text-m;
  }

  .cta {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @include mobtablet {
    h4 {
      margin-bottom: $spacing-mobile-text-l;
    }
    .contact-email {
      margin-top: $spacing-mobile-text-l;
    }
  }
}
