.radio-toggle {
  text-align: center;
  background-color: $FT_white;
  padding: 1rem $site-column-gutter;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  line-height: 22px;
  user-select: none;

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    left: -9999px;
  }

  label {
    position: relative;
    background: $FT_limestone;
    padding: 0.5rem 2rem;
    font-size: 18px;
    display: block;
    min-width: 125px;
    cursor: pointer;

    @include mobile {
      font-size: 14px;
    }

    &::after {
      content: " ";
      margin-left: 10px;
      display: inline-block;
      width: 9px;
      height: 15px;
      transform: rotate(45deg);
      position: relative;
      top: -2px;

      @include mobile {
        top: 0px;
      }
    }

    &.active {
      background: $FT_anthracite;
      color: $FT_white;

      &::after {
        border-right: 2px solid white;
        border-bottom: 2px solid white;
      }
    }

    &:first-child {
      border-radius: 9000px 0 0 9000px;
    }

    &:last-child {
      border-radius: 0 9000px 9000px 0;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
