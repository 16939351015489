.fact-block {
  margin-bottom: $spacing-block-s;
  h4 {
    margin-bottom: $spacing-text-m;
  }

  @include mobtablet {
    margin-bottom: $spacing-mobile-block-m;
  }
}
