.footer-block .navigation-link-primary {
  font-weight: 100;
  margin-top: $spacing-mobile-block-l;
  @include mobtablet {
    border-top: solid 1px $FT_shale;
    padding-top: $spacing-mobile-block-s;
    margin-top: $spacing-mobile-block-s;
  }
}
.footer-link-list {
  padding: 0;
  margin: $spacing-text-m 0 $spacing-block-xs;

  @include mobtablet {
    margin: 0;
    padding: $spacing-mobile-block-s 0 0;
  }

  li {
    margin: 0 0 $spacing-block-xxs;
    display: block;
    line-height: 1.5;
    @include mobtablet {
      &:last-child {
        margin: 0;
      }
      &:first-child {
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  h4 {
    color: $FT_pebble;
    margin: 0 0 $spacing-block-xxs;
    @include mobtablet {
      margin: 0;
    }
  }
}
