.amount-wrapper {
  background-color: $FT_white;
  padding: $site-column-gutter/2 $site-column-gutter $site-column-gutter;
  text-align: right;
  border-top: 1px solid #dadce0;

  @include mobile {
    padding: $spacing-mobile-block-s $spacing-block-xxxs 25px
      $spacing-block-xxxs;
  }

  .discount,
  .amount {
    display: flex;
    justify-content: space-between;
    font-size: 30px;
    font-weight: 400;

    @include mobile {
      font-size: 20px;
      font-weight: 500;
    }
    .price {
      transition: color 0.1s ease-in-out;
    }
    .is-loading {
      color: $FT_limestone;
    }
  }
  .discount {
    font-size: 1.2rem;
    font-weight: 300;
    @include mobile {
      font-size: 1rem;
      font-weight: 300;
    }
  }

  .currency {
    padding: 0;
    font-weight: 300;
    font-size: 15px;
    text-decoration: underline;
    color: $FT_anthracite;
    cursor: pointer;

    @include mobile {
      font-size: 13px;
    }
  }
}
