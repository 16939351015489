// PRIMARY COLORS
$FT_orange: #fd4f00;
$FT_orange_link: #d44300;
$FT_anthracite: #303e48;
$FT_shale: #5a6771;
$FT_limestone: #dadce0;

// BG COLORS
$FT_limestone_light: #ecebee;
$FT_grey_light: #f9f8f9;
$FT_beige_light: #f4efec;
$FT-grey-bg: #5e666f;

$FT_firebrick: #8f153a;
$FT_past-travel-results_bg: #dad7de;

// TEXT COLORS
$FT_black: #000;
$FT_white: #fff;
$FT_grey: #5c6670;
$FT_WEB_disabled: #949494;
$FT_past-travel-results_color: #626b75;


// FOOTER COLORS:
$FT_clay: #a69584;
$FT_clay_light: #b6a491;

$FT_pebble: #d3ccbc;
$FT_slate: #5d8096;
$FT_stone: #a4c6e1;

$FT_pine: #0f5d67;
$FT_juniper: #2d988a;
$FT_juniper_dark: #23766b;

// DEVIATION COLOR (/AVVIKFARGE)
$FT_glow: #fab529;
$FT_glow_light: #fef0d4;
$FT_flame: #cf323e;

// DEVIATION COLOR (/AVVIKFARGE) L2
$FT_dev_red: #d1303a;
$FT_dev_red_dark: #8f1639;
$FT_dev_green: #b8dbd6;
$FT_dev_green_dark: #278376;
$FT_dev_green_text: $FT_anthracite;
$FT_dev_yellow: $FT_glow_light;
$FT_dev_yellow_dark: #ffc348;
$FT_dev_yellow_text: #343e47;
$FT_dev_gray: #3d5062;
$FT_dev_gray_text: white;

$FT_footer: $FT_anthracite;
$FT_footer_orange: $FT_orange;
$FT_footer_green: $FT_juniper_dark;

// FONT
$FT_font_family: 'Roboto', sans-serif;

// BLOCK SPACING
$spacing-block-xxxs: 15px;
$spacing-block-xxs: 20px;
$spacing-block-xs: 60px;
$spacing-block-tmp: 80px;
$spacing-block-s: 100px;
$spacing-block-m: 120px;
$spacing-block-xl: 150px;

$spacing-mobile-block-s: 20px;
$spacing-mobile-block-m: 30px;
$spacing-mobile-block-l: 60px;
$spacing-mobile-block-xl: 80px;

// TEXT SPACING
$spacing-text-s: 10px;
$spacing-text-m: 20px;
$spacing-text-l: 30px;
$spacing-text-xl: 40px;

$spacing-mobile-text-s: 5px;
$spacing-mobile-text-m: 10px;
$spacing-mobile-text-l: 20px;
$spacing-mobile-text-xl: 30px;

// SIZES
$site-lg-width: 1200px;
$site-column-gutter: 35px;
$site-column-gutter-travel-planner: 40px;
$site-column-gutter-mobile-travel-planner: 30px;
$image-max-width: 1920px;

$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

$headerHeightMobile: 50px;
$headerHeightDesktop: 120px;

$mobile: '(max-width: ' + #{$screen-sm - 1} + ')';
$tablet: '(min-width: ' + #{$screen-sm} + ') and (max-width: ' + #{$screen-md - 1} +
  ')';
$desktop: '(min-width: ' + #{$screen-md} + ')';
$mobtablet: '(max-width: ' + #{$screen-md - 1} + ')';

@mixin mobile {
  @media (max-width: #{$screen-sm - 1}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$screen-sm}) and (max-width: #{$screen-md - 1}) {
    @content;
  }
}
@mixin mobtablet {
  @media (max-width: #{$screen-md - 1}) {
    @content;
  }
}

@mixin tabletdesktop {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

@mixin background-opacity($color, $opacity) {
  background: $color;
  background: rgba($color, $opacity);
}

$FT_triangle_angle: 40deg;
// Based on images from sketch where height / width*100 -> 368/555*100 = 66 (rounded down)
$FT_image_ratio: 66%;

// Focus mixin
%focus-state {
  box-shadow: inset 0 0 0px 2px #8694a1;
}
