.promo-block {
  position: relative;
  width: 100%;
  height: 750px;

  .promo-image,
  .promo-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .promo-image {
    background-repeat: no-repeat;
    background-size: cover;
  }

  .promo-gradient {
    opacity: 0.8;
    background: linear-gradient(
      to bottom,
      rgba(9, 92, 105, 1) 0%,
      rgba(143, 22, 57, 1) 100%
    );
  }

  .promo-text {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 25;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    h1 {
      color: $FT_white;
    }
  }
}
