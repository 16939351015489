.stepper-row {
  padding: $spacing-block-xxxs 0;
  @include mobile {
    padding: $spacing-block-xxxs/2 0;
  }
}

.stepper-row-upper {
  display: flex;
  align-items: flex-start;

  .product-name,
  .actual-price {
    font-size: 30px;
    line-height: 30px;

    @include mobile {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
    }
  }

  .stepper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    min-width: 120px;
    margin: 0 20px;
    font-family: $FT_font_family;
    font-size: 30px;
    line-height: 35px;
    color: $FT_font_family;
    text-align: center;

    span {
      @include mobile {
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
      }
    }

    @include mobile {
      align-items: center;
      margin: 5px;
      min-width: auto;
      width: 100px;
      margin: 0 auto;
    }
  }

  .product-name {
    width: 36%;

    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-end;
    justify-content: center;

    @include mobile {
      min-width: auto;
      width: 33%;
    }
  }

  .actual-price {
    position: relative;
    display: inline-block;
    margin-left: auto;
    transition: color 0.1s ease-in-out;

    &.is-loading {
      color: $FT_limestone;
    }

    line-height: 35px;

    @include mobile {
      min-width: auto;
      width: 33%;
      text-align: right;
      align-self: center;
      margin-left: 0;
    }
  }
}

.step {
  border: 2px solid $FT_anthracite;
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 6px;
  padding: 0;
  padding-bottom: 2px;

  font-family: $FT_font_family;
  font-weight: 300;
  font-size: 24px;
  line-height: 24px;
  color: $FT_anthracite;
  &:disabled {
    cursor: not-allowed;
    border-color: $FT_WEB_disabled;
    color: $FT_limestone;
  }
}

.product-price {
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;
  margin-top: 0.5rem;

  @include mobile {
    font-size: 14px;
    line-height: 16px;
    margin-top: 0.1rem;
  }
  &.discounted {
    text-decoration: line-through;
  }
}

.product-message {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: $FT_anthracite;
}
