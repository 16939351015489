@import "../../shared-components-styles/travel-and-shop-mixins.scss";
.shop-info-container {
  @include travel-and-shop-container;
}

.shop-info {
  width: 650px;
  @include mobile {
    width: 100%;
    max-width: 650px;
  }
}

.shop-info-inner {
  padding: 0 70px;

  @include mobile {
    padding: 0 35px;
    max-width: 650px;
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
  }
  .row.travel-inputs {
    border-bottom: solid 1px $FT_limestone;
    margin-bottom: 1rem;
  }
  .travel-input {
    @include tabletdesktop {
      max-width: calc(50% - 25px);
    }
  }
  .logo {
    margin: 0 auto;
    max-width: 400px;
  }
  background-color: $FT_white;
  .editorial-text {
    text-align: center;
    margin: 2em 0;
    h4 {
      display: block;
      font-size: 20px;
      font-weight: 400;
    }
    > div {
      display: flex;
      justify-content: center;
      text-align: left;
    }
  }

  ul {
    display: inline-block;
    li {
      list-style-type: none;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      margin-left: -30px;
      position: relative;
      top: 4px;
    }
  }
}
