.menu-link {
    $self: &;
    color: inherit;
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 18px;
    display: inline-flex;
    outline: none;
    font-weight: 400;

    &:focus,
    &:hover,
    &_active {
        text-decoration: underline;
    }

    .touchevents &:focus,
    .mouse-user &:focus {
        text-decoration: none;
    }

    @media (min-width: #{$screen-lg}) {
        font-size: 25px;
        line-height: 39px;
        margin-bottom: 20px;
    }

    &_in-language-selector{
        font-size: 16px;
        line-height: 1;
        margin: 0;
        font-weight: 300;
        
        @media (min-width: #{$screen-lg}) {
            font-size: 25px;
        }

        &.menu-link_active{
            text-decoration: none;
        }
    }

    &_secondary {
        font-size: 16px;
        line-height: 21px;
        margin: 0 0 17px;
        font-weight: 300;

        @media (min-width: #{$screen-lg}) {
            font-size: 25px;
            line-height: 33px;
            margin-bottom: 20px;
        }
    }

    &_second-level {
        color: inherit;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 15px;
        display: inline-flex;
        margin-left: 20px;
        font-weight: 300;

        &:focus,
        &:hover {
            text-decoration: underline;
        }

        .touchevents &:focus,
        .mouse-user &:focus {
            text-decoration: none;
        }

        @media (min-width: #{$screen-lg}) {
            font-size: 22px;
            line-height: 33px;
            margin-bottom: 20px;
        }
    }
}