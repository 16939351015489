$button-border-radius: 4px;

@mixin button_text {
  font-family: $FT_font_family;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
}

@mixin button {
  @include button_text;
  padding: 17px 30px;
  margin: 0 20px;
  cursor: pointer;
  // border-radius: $button-border-radius;
  // Need to use default a transparent border so that the size of
  // buttons are the same regardless of whether it has a border or not.
  border: solid 2px transparent;
  z-index: 2;
}

@mixin button_hover {
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -150%;
    width: 130%;
    height: 100%;
    transition: transform 0.4s ease-out, opacity 0.4s ease-out;
    transform: translate(0, 0) skew($FT_triangle_angle);
    background: $FT_orange_link;
    opacity: 1;
    // z-index: -1; // causes text to be hidden when modal window is shorter than the content and the button ends up outside the overflow area.
    will-change: transform;
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;

    &:hover {
      &:before {
        transform: none;
        opacity: 0;
      }
    }
    /*
		background: $FT_grey_light;
		color: $FT_slate;
		cursor: not-allowed;
		&:before{
			opacity: 0;
		}
		&:hover{
			&:before{
				transform: none;
				opacity: 0;
			}
		}
		*/
  }
  &:hover {
    &:before {
      transform: translate3d(100%, 0, 0) skew($FT_triangle_angle);
      opacity: 0.1;
    }
  }
}

@mixin button_primary {
  background: $FT_orange_link;
  color: $FT_white;
  &:before {
    background: #e5e5e5;
    opacity: 0.6;
  }
}

@mixin button_secondary {
  background: $FT_white;
  border: solid 2px $FT_orange_link;
  color: $FT_orange_link;
  &:before {
    background: $FT_limestone_light;
  }
}

@mixin button_superior {
  cursor: pointer;
  background: $FT_orange;
  color: $FT_white;
  font-family: $FT_font_family;
  font-size: 18px;
  line-height: 18px;
  border-radius: 50px;
}

@mixin button_squared {
  cursor: pointer;
  text-transform: none;
  background: $FT_orange;
  color: $FT_white;
  font-family: $FT_font_family;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  border-radius: 6px;
  &:before {
    left: -150%;
  }
}

@mixin button_stripped {
  font-family: $FT_font_family;
  background: none;
  border: 0;
  color: $FT_orange;
  padding: 0;
  margin: 0;
}

@mixin cta {
  font-family: $FT_font_family;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.67;
  color: $FT_orange_link;
  text-decoration: underline;
  word-break: break-word;

  @include mobtablet {
    font-size: 15px;
    line-height: 1.2;
  }

  &:hover {
    color: $FT_anthracite;
  }
}

@mixin navigation_link {
  font-family: $FT_font_family;
  font-weight: 300;
  color: $FT_white;
  text-decoration: none;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
}

@mixin navigation_link_primary {
  @include navigation_link;
  font-size: 23px;
  line-height: 1.2;

  @include mobtablet {
    font-size: 22px;
    line-height: 1.36;
  }
}

@mixin navigation_link_secondary {
  @include navigation_link;
  font-size: 18px;
  line-height: 1.56;

  @include mobtablet {
    font-size: 14px;
    line-height: 1.14;
  }
}

@mixin navigation_link_tertiary {
  @include navigation_link;
  color: $FT_limestone;
  font-size: 16px;
  line-height: 1;
}
