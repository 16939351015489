@media only print {
  .menu-header-container,
  .footer-block {
    display: none;
  }

  .fluid-image {
    height: auto !important;
    img {
      opacity: 1;
      height: auto;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    page-break-after: avoid;
    page-break-inside: avoid;
  }
  img,
  table,
  figure,
  iframe {
    page-break-inside: avoid;
  }
  video {
    display: none !important;
  }

  iframe {
    &.iframe-yt {
      display: none !important;
    }
  }

  @page {
    margin: 1.5cm;
  }

  .chatbutton {
    display: none !important;
  }

  /** We want to remove animations from when we're doing prints. */
  .highlight-block,
  .block-in-grid {
    animation-name: none !important;
    visibility: visible !important;
  }

  .block-in-grid .image-container,
  .block-in-grid a .fluid-image {
    height: auto !important;
  }

  .picture-block {
    height: auto !important;
    .picture-container,
    .picture-background {
      position: relative !important;
      left: inherit !important;
      top: inherit !important;
    }
    .picture-skew-container {
      display: none !important;
    }
    .picture-content {
      position: static !important;
      height: auto !important;
      top: inherit !important;
      left: inherit !important;
      visibility: visible !important;
      animation-name: none !important;
    }
  }

  .image-triangle-container {
    display: none !important;
  }
}
