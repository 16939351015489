.skip-to-main {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  background: $FT_past-travel-results_bg;
  display: flex;
  padding: 20px;
  color: #313e48;
  font-weight: bold;
  transition: transform 100ms ease-in-out, opacity 100ms linear;
  transform: translateY(-100%);
  outline: none;
  font-family: $FT_font_family;
  font-size: 20px;
  border-radius: 3px;

  &:focus {
    transform: none;
  }

  @media print {
    display: none;
  }
}
