$emergency-block-width: 650px;
$emergency-block-padding: 50px;

@mixin emergency-block-container {
  position: relative;
  width: 100%;
  max-width: $emergency-block-width;
  margin: 0 auto;

  @include mobile {
    max-width: calc(#{$emergency-block-width} - 20px);
  }
}

.emergency {
  $self: &;

  &-heading {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 300;
    flex: 0 0 100%;
    align-self: center;
    cursor: pointer;

    @media (min-width: $screen-sm) {
      font-size: 22px;
      line-height: 1.2;
    }
  }

  &-button-container {
    flex: 0 0 0;
    max-width: 0px;
    align-self: center;
    position: relative;
  }

  &-toggle-button,
  &-close-button {
    padding: 0;
    margin: 0;
    border: none;
    display: block;
    cursor: pointer;
    color: inherit;

    .mouse-user & {
      outline: none;
    }

    .icon {
      width: 13px;
      height: 13px;

      @media (min-width: 1200px) {
        width: 16px;
        height: 16px;
      }
    }
  }

  &-toggle-button {
    right: 22px;
    font-family: inherit;
    text-decoration: underline;
    margin-left: 18px;
    display: flex;
    flex-direction: row;

    @media (min-width: 1200px) {
      margin-left: $emergency-block-padding;
    }

    &:focus,
    &:hover {
      text-decoration: none;
    }

    &-text {
      font-size: 18px;
      line-height: 1;
      font-weight: 300;
      margin-right: 12px;
      align-self: center;
      white-space: nowrap;

      @media (max-width: 1300px) {
        display: none;
      }
    }

    .icon-collapse {
      transition: transform ease-in-out 0.5s;
      align-self: center;
    }

    &.is-expanded .icon-collapse {
      transform: rotate(-180deg);
    }
  }

  &-close-button {
    margin-right: 18px;
    float: right;

    @media (min-width: 1200px) {
      margin-right: $emergency-block-padding;
    }
  }

  &-block {
    &.no-emergency {
      display: none;
    }

    &-text {
      padding-bottom: 25px;

      @media (min-width: 1200px) {
        padding-bottom: 60px;
      }
    }

    .emergency-read-more,
    .emergency-close {
      cursor: pointer;
    }

    &.is-yellow {
      background: $FT_glow_light;
    }
    &.is-red {
      background: $FT_dev_red;
    }
    &.is-gray {
      background: $FT_dev_gray;
      color: $FT_dev_gray_text;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $FT_dev_gray_text;
      }
    }

    &-content {
      position: relative;
      padding: 0;
      border: solid 5px $FT_glow_light;
      border-top: 0;

      &.is-red {
        border: solid 5px $FT_dev_red;
        border-top: 0;
      }

      &.is-gray {
        border: solid 5px $FT_dev_gray;
        border-top: 0;
        color: $FT_dev_gray_text;
      }

      h2 {
        margin-bottom: $spacing-text-xl;
      }

      .emergency-date {
        margin-bottom: $spacing-text-l;
      }
    }
  }

  &-banner-container {
    @include emergency-block-container;
    padding: 25px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @include mobile {
      padding: 20px $emergency-block-padding;
    }
  }

  &-container {
    @include emergency-block-container;

    @include mobile {
      padding-right: $emergency-block-padding;
      padding-left: $emergency-block-padding;
    }

    .travel-planner-list-view & {
      max-height: 50vh;
      overflow: auto;
    }
  }
}
