.anim-triangle-container,
.anim-triangle-container-2 {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
}

.anim-triangle-container {
  transform: rotate(135deg);
}

.anim-triangle-1 {
  background: $FT_limestone;
  /*width: 3000px;
	height: 3000px;*/
  width: 1000px;
  height: 1000px;
  z-index: 17;
  opacity: 0.2;
  transform: translate3d(-40%, 20%, 0);
  animation: anim-triangle-1 2.2s ease-out 0.5s 1 forwards;
}

.anim-triangle-container-2 {
  transform: rotate(30deg);
}
.anim-triangle-2 {
  background: $FT_limestone;
  /*width: 3000px;
	height: 3000px;*/
  width: 1000px;
  height: 1000px;
  z-index: 17;
  opacity: 0.3;
  transform: translate3d(0%, 20%, 0);
  animation: anim-triangle-2 2.2s ease-out 1s 1 forwards;
}

@keyframes anim-triangle-1 {
  0% {
    transform: translate3d(-40%, 20%, 0);
    opacity: 0.2;
  }
  100% {
    transform: translate3d(-60%, -30%, 0);
    opacity: 0;
  }
}

@keyframes anim-triangle-2 {
  0% {
    transform: translate3d(0%, 20%, 0);
    opacity: 0.3;
  }
  100% {
    transform: translate3d(20%, -20%, 0);
    opacity: 0;
  }
}
