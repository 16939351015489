.highlight-blocks {
  padding: $spacing-block-tmp 0;
  background: $FT_grey_light;

  &.type-grey {
    background: $FT_grey_light;
  }
  &.type-beige {
    background: $FT_beige_light;
  }
  &.type-limestone {
    background: $FT_limestone_light;
  }
  &.type-white {
    background: $FT_white;
  }

  .highlight-text {
    margin-top: $spacing-text-s;
  }
  @include mobile {
    padding: $spacing-mobile-block-m 0;

    .highlight-block {
      margin-top: $spacing-mobile-block-s;
      h2 {
        font-weight: 300;
      }
      &:first-child {
        margin-top: 0;
      }
    }
    .highlight-text {
      margin-top: 0;
    }
  }
}

.highlight-block {
  .fluid-image {
    max-width: 131px;
    margin-bottom: $spacing-text-s;
    margin-left: $spacing-text-l;
    width: 100%;
    height: auto;

    @include mobile {
      max-width: 122px;
      margin-left: $spacing-mobile-text-xl;
    }
  }

  .image-container {
    &:after {
      display: none;
    }
    .image-wrapper {
      position: relative;
    }
  }
}
