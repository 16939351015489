@mixin full-size {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
}

@mixin fluid-image() {
	padding: 0px;
	@include full-size;
	background-position: center;
	background-size: cover;
	max-width: none;
	position: absolute !important;
    height: auto !important;

	.responsive-image__img {
		opacity: 0;
	}
}

@mixin original-proportions-image($width, $height) {
	padding: 0;
	height: auto;

	.responsive-image__img {
		position: relative;
		height: auto;
		width: auto;
		max-width: 100%;
	}

	&.responsive-image_in-ie .responsive-image__img {
		display: block;
	}
}

.responsive-image {
  display: block;
  width: 100%;
  background-repeat: no-repeat;
  overflow: hidden;

  &_fluid {
    @include fluid-image();
  }

  &__img {
    display: block;
    width: 100%;
  }

  &_size {
    &_header-logo {
      @include original-proportions-image(139, 25);
	  @media (min-width: #{$screen-lg}) {
		@include original-proportions-image(250, 45);
	  }
    }
  }
}
