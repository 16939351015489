.quick-search {
  $self: &;
  position: relative;
  margin: 13px 0 30px;

  @include desktop {
    margin: 12px 0 57px;
  }

  &__form {
    margin: auto;
  }

  &__container {
    display: flex;
    width: 100%;
    background: $FT_grey;
    height: 52px;
    border-radius: 2px;
    overflow: hidden;
    padding-right: 20px;

    @media (min-width: #{$screen-lg}) {
      height: 66px;
    }
  }

  &__input {
    width: 100%;
    padding: 10px 0 10px 20px;
    background: $FT_grey;
    border: none;
    align-self: center;
    height: 52px;
    color: $FT_white;
    outline: none;
    font-size: 16px;

    &::placeholder {
      color: inherit;
    }

    @media (min-width: #{$screen-lg}) {
      font-size: 25px;
      padding: 10px 0 10px 34px;
    }
  }

  &__search-button {
    @include button-clean;
    align-self: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    #{$self}__input:focus + &,
    &:focus{
      opacity: 1;
    }

    &,
    &-icon {
      width: 20px;

      @media (min-width: #{$screen-lg}) {
        width: 27px;
      }
    }
  }
}
