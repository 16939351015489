@import "../../shared-components-styles/travel-and-shop-mixins.scss";
.shopping-cart-container {
  @include travel-and-shop-container;
  margin-top: 0;
}

.shopping-cart {
  -webkit-text-size-adjust: 100%; // safari horizontal view changes font sizes without this.
  width: 650px;
  @include mobile {
    width: 100%;
    max-width: 650px;
  }
}

.shopping-cart-inner {
  position: relative;
  width: 650px;

  @include mobile {
    margin: auto;
    max-width: 650px;
    width: calc(100% - 20px);
  }
  .row.travel-inputs {
    border-bottom: solid 1px $FT_limestone;
    margin-bottom: 1rem;
  }
  .travel-input {
    @include tabletdesktop {
      max-width: calc(50% - 25px);
    }
  }
  background-color: $FT_white;
}

.centered {
  text-align: center;
}

.shopping-row {
  padding: 0 $site-column-gutter;
  font-size: 18px;
  @include mobile {
    font-size: 14px;
  }
}

.stepper-row-wrapper {
  background-color: $FT_white;
  padding: 0 $site-column-gutter $site-column-gutter $site-column-gutter;
  @include mobile {
    padding: 0 $spacing-block-xxxs 25px $spacing-block-xxxs;
  }
}
