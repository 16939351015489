.image-gallery-block {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  @include image-max-width;

  .image-gallery-container {
    position: relative;
    height: 540px;
  }
  .image-gallery-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.8s ease-out;
  }
  .image-text-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: $site-lg-width;
    margin: $spacing-text-m auto 0;
  }
  .image-text-item {
    position: relative;
    flex-basis: get-col-percent(12);
    max-width: get-col-percent(12);
    padding-left: $site-column-gutter;
    padding-right: $site-column-gutter;
    @media only screen and (min-width: 768px) {
      margin-left: get-col-percent(4);
      flex-basis: get-col-percent(8);
      max-width: get-col-percent(8);
    }

    display: none;
    height: 100%;
    color: $FT_grey;
    font-size: 16px;
    line-height: 1.5;
    &.is-active {
      display: flex;
      flex-flow: column;
    }
  }
  .image-gallery-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: $FT_shale;*/
    // To remove jagged edges.
    outline: solid 1px transparent;
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.5s ease-out;
    z-index: 15;
    overflow: hidden;
    &.is-active {
      transform: translate3d(0, 0, 0);
      z-index: 10;
      ~ .image-gallery-item {
        z-index: 5;
        transform: translate3d(5%, 0, 0);
        .fluid-image {
          transform: translate3d(0, 0, 0);
        }
        .image-skew-container {
          opacity: 0;
          transform: translate3d(-100%, 0, 0);
        }
      }
      .fluid-image {
        transform: translate3d(0, 0, 0);
      }
      .image-skew-container {
        opacity: 1;
        transform: translate3d(0%, 0, 0);
      }

      .image-content {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }

    .fluid-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: translate3d(95%, 0, 0);
      transition: transform 0.5s ease-out;
    }
    .image-skew-container {
      transform: translate3d(-100%, 0, 0);
      opacity: 0;
      transition: transform 0.5s ease-out 0.3s, opacity 0.5s ease-out 0.3s;
    }
  }

  .controls {
    position: absolute;
    bottom: 125px;
    height: 0;
    z-index: 20;
    width: 100%;
  }

  .btn-next,
  .btn-previous {
    display: flex;
    align-items: center;
    position: absolute;
  }

  .btn-previous {
    left: $site-column-gutter;
  }

  .btn-next {
    right: $site-column-gutter;
  }

  .btn-text {
    color: $FT_white;
    font-size: 50px;
    font-weight: 100;
    line-height: 100px;

    @include mobile {
      display: none;
    }
  }

  .active-image,
  .disabled-image {
    width: 80px;
    height: 80px;
    @include mobtablet {
      width: 40px;
      height: 40px;
    }

    polygon {
      fill: $FT_white;
    }
  }

  .active-image {
    &.has-previous,
    &.has-next {
      display: block;
    }
  }
  .disabled-image {
    display: block;
    &.has-previous,
    &.has-next {
      display: none;
    }
  }

  .rotated-image {
    transform: rotate(180deg);
  }

  @include mobtablet {
    .image-gallery-container {
      height: 320px;
    }
    .controls {
      bottom: 100px;
    }
    .btn-text {
      font-size: 35px;
      font-weight: 300;
    }
  }
}
