@mixin travel-and-shop-container{
  font-family: $FT_font_family;
  color: $FT_anthracite;
  z-index: 99;
  padding: 0 0 3.5rem;
  margin-top: 3.5rem;
  margin-left: auto;
  margin-right: auto;

  @include mobtablet {
    margin-top: 1.5rem;
    padding: 10px 0 2.5rem;
  }

  svg {
    fill: $FT_anthracite;
  }
  
  [class^='col-'] {
    padding: 0 $site-column-gutter-travel-planner;
  }
  
  @include mobile {
    [class^='col-xs-'] {
      padding: 10px $site-column-gutter-mobile-travel-planner;
    }
  }

  .row .row {
    margin: 0 auto;
  }
}