.picture-block {
  position: relative;
  height: 700px;
  @include image-max-width;

  .picture-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    .picture-image {
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }
  .picture-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    .picture-skew-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .picture-skew {
      position: absolute;
      right: calc(50% + (1060px / 2));
      top: 0;
      width: 5000px;
      height: 100%;
      transform: skew($FT_triangle_angle);
      transform-origin: top left;
      background: $FT_white;
      z-index: 5;
      opacity: 0.9;
    }
    .picture-content {
      position: relative;
      z-index: 10;
      width: 100%;
      height: 20%;
      top: 50%;
      left: 0;
    }

    .contextual-text {
      color: $FT_shale;
    }

    h4 {
      margin-top: $spacing-text-m;
      margin-bottom: $spacing-text-m;
      max-width: 250px;
      @include mobile {
        max-width: none;
        margin-top: $spacing-mobile-text-m;
        margin-bottom: $spacing-mobile-text-m;
      }
    }
  }

  .mobile-pointer {
    display: none;
  }

  @include mobtablet {
    height: auto;

    .picture-background {
      position: relative;
      height: 225px;
    }

    .picture-container {
      position: relative;
    }

    .contextual-text {
      margin-top: $spacing-mobile-text-l;
    }

    .picture-content {
      position: relative;
      padding-bottom: $spacing-mobile-block-m;
    }
    .mobile-pointer {
      position: absolute;
      left: $site-column-gutter + 5px;
      top: -15px;
      display: block;
      height: 35px;
      width: 35px;
      background: $FT_white;
      transform: rotate3d(0, 0, 1, 45deg);
      z-index: -1;
    }
  }
}

@keyframes picture-triangle {
  0% {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
