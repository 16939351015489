@import "../../shared-components-styles/travel-and-shop-mixins.scss";

.payment-spinner{
  display: flex;
  width: 100%;
  height: calc(100vh - 300px);
  justify-content: center;
  align-items: center;
  padding: 20px;

  @include mobile {
    height: calc(100vh - 150px);
  }

  &__content{
    display: flex;
    flex-direction: column;
    max-width: $site-lg-width;
  }

  &__icon{
    align-self: center;
    margin-bottom: 40px;

    @media (min-width: #{$screen-lg}) {
      margin-bottom: 120px;
    }
  }

  &__text{
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: white;
  }
  
}
