.menu-section {
    display: flex;
    flex-direction: column;
    margin: 0 0 30px;

    @media (min-width: #{$screen-lg}) {
        margin-bottom: 91px;
    }

    &__item{
        width: 100%;
    }

    &:last-child{
        margin-bottom: 0;
    }
}
