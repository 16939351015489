.price-overview-block {
  background: $FT_beige_light;
  padding: $spacing-block-tmp 0 $spacing-block-tmp;

  .price-overview-content {
    margin-top: $spacing-mobile-block-m;
  }

  .price-overview-list {
    .price-item {
      display: flex;
      margin-bottom: $spacing-text-s;

      &.price-item-link {
        margin-top: $spacing-text-l;
      }

      &.price-item-header {
        margin-bottom: $spacing-text-l;
      }

      .price-adults,
      .price-other {
        margin-right: $spacing-text-s;
        text-align: right;
      }
      .price-station {
        flex: 1;
      }
      .price-adults {
        width: 75px;
      }
      .price-other {
        width: 200px;
        overflow: hidden;
        word-break: break-word;
      }
      .price-link {
        margin-top: 2rem;
        text-decoration: underline;
        word-break: break-word;
      }
    }
  }

  .editorial-text {
    border-left: solid 1px $FT_shale;
    padding-left: $spacing-text-l;
  }
  @include mobtablet {
    .price-overview-list {
      .price-item-header {
        font-size: 15px;
        line-height: 1.33;
      }
      .price-item {
        .price-adults {
          width: 75px;
        }
        .price-other {
          width: 90px;
        }
      }
    }
  }
  @include mobile {
    padding: $spacing-mobile-block-l 0 $spacing-mobile-block-l;

    .price-overview-content {
      margin-top: $spacing-mobile-block-s;
    }
    .editorial-text {
      margin-top: $spacing-mobile-block-l;
      border-left: 0;
      padding-left: 0;
    }
    .price-overview-list {
      .price-item {
        .price-station {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .price-other {
          width: 75px;
        }
      }
    }
  }
}
