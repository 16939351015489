.modal-backdrop {
  position: fixed;
  z-index: 1001;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  animation: in-anim-bg 0.3s ease-out forwards;
  &.no-anim {
    animation: none;
  }
  &.closing {
    animation: in-anim-bg 0.3s ease-out reverse forwards;
  }
}

// The modal "window"
.modal-content {
  position: relative;
  background-color: #fff;
  max-width: 650px;
  width: 100%;
  min-height: 30vh;
  max-height: 85vh;
  margin: 0 auto;
  padding: $spacing-block-xxs;
  padding-top: 50px;
  animation: in-anim 0.3s ease-out;
  overflow: auto;

  @include mobile {
    max-height: 100vh;
    padding: 50px 10px 10px;
    width: calc(100% - 20px);
  }
  .no-anim & {
    animation: none;
  }

  .closing & {
    animation: out-anim 0.3s ease-out forwards;
  }

  h2 {
    text-align: center;
    font-weight: 300;
  }

  .header3 {
    @include FT_H3;

    text-align: center;
  }

  > div {
    margin-bottom: 1em;
  }

  p {
    padding: $spacing-block-xxs 0;
    text-align: center;

    &.modal-content-left-aligned {
      text-align: left;
    }
  }

  a {
    color: $FT_black;
    text-decoration: underline;

    &:visited {
      color: $FT_black;
    }
  }

  ul {
    margin: 15px 0 35px 0;

    li {
      display: block;
      list-style-type: none;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
  }
  img {
    max-width: 100%;
  }
  button {
    display: block;
    margin: 0 auto;
    font-size: 16px;
    text-transform: none;

    @include mobile {
      font-size: 14px;
    }
  }
  .select-wrapper {
    margin-bottom: $spacing-mobile-block-m;
  }
}

.select-wrapper {
  position: relative;

  .custom-select {
    select::-ms-expand {
      display: none;
    }
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    cursor: pointer;

    > select {
      width: 100%;
      border: 1px solid $FT_orange_link;
      background-color: $FT_white;
      font-size: 16px;
      font-weight: 300;
      color: $FT_orange_link;
      line-height: 25px;
      padding: 8px 35px 8px 12px;
      cursor: pointer;
    }
  }

  &:after {
    content: " ";
    height: 0;
    position: absolute;
    width: 0;
    border: 9px solid transparent;
    border-top-color: $FT_orange_link;

    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 25px;
    pointer-events: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }
}

@keyframes in-anim {
  0% {
    transform: translateY(-3em);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes out-anim {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-3em);
  }
}

@keyframes in-anim-bg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1rem;
  height: 1.2rem;
  padding: 0;
  display: block;
  cursor: pointer;
}

.modal-content button svg {
  width: 100%;
  height: 100%;
}
