.image-container {
  position: relative;
  overflow: hidden;

  &:after {
    padding-top: $FT_image_ratio;
    content: "";
    display: block;
  }
  img {
    width: 100%;
    height: auto;
  }

  .fluid-image {
    width: 100%;
    height: 100%;
  }

  .image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .image-download-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include background-opacity($FT_anthracite, 0.7);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    &:hover {
      opacity: 1;
      .image-download-text {
        opacity: 1;
      }
    }
  }

  .image-download-text {
    color: $FT_white;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.8;
    font-weight: 700;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }
}
