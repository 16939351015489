.page-header {
    $self: &;
    background: $FT_anthracite;
    color: $FT_white;
    z-index: 105;
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 0 10px;
    height: $headerHeightMobile;

    @media (min-width: #{$screen-lg}) {
        height: $headerHeightDesktop;
        padding: 0 35px;
    }

    &__logo {
        display: block;
        align-self: center;
        width: 139px;
        
        @media (min-width: #{$screen-lg}) {
            width: 250px;
        }
    }

    &__toggle-button {
        position: fixed;
        top: 17px;
        right: 22px;
        z-index: 2000;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        color: $FT_white;
        cursor: pointer;
        padding: 0;
        margin: 0;
        border: none;
        font-family: $FT_font_family;

        @media (min-width: #{$screen-lg}) {
            top: 46px;
            right: 45px;
        }

        &-text{
            align-self: center;
            margin-right: 6px;
            font-size: 16px;
            line-height: 1;
            display: none;
            font-weight: 100;

            @media (min-width: #{$screen-lg}) {
                font-size: 25px;
                margin-right: 20px;
                display: block;

                #{$self}__toggle-button_active &{
                    margin-right: 28.5px;
                }
            }

            #{$self}__toggle-button_active &{
                display: block;
            }
        }

        &-close-icon,
        &-open-icon {
            align-self: center;
            display: block;
            height: 15px;
            fill: $FT_white;

            @media (min-width: #{$screen-lg}) {
                height: 30px;
            }
        }

        &-close-icon{
            height: 12px;
            @media (min-width: #{$screen-lg}) {
                height: 30px;
                width: 30px;
            }
        }
    }

    &__navigation-panel{
        position: fixed;
        z-index: 105;
        top: 0;
        right: 0px;
        background: $FT_anthracite;
        height: 100%;
        padding: 0 20px;
        width: 100%;
        color: $FT_white;

        @media (min-width: #{$screen-lg}) {
            padding: 0 45px;
        }

        @include desktop{
            width: 483px;
        }
    }
    
    &__menu{
        position: absolute;
        bottom: 0px;
        left: 0;
        overflow-y: auto;
        width: 100%;
        height: calc(100% - 145px);
        padding-left: 40px;

        @media (min-width: #{$screen-lg}) {
            padding-left: 45px;
            padding-right: 45px;
            height: calc(100% - 255px);
        }
    }

}
