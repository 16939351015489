@import "../../shared-components-styles/travel-and-shop-mixins.scss";
.travel-planner {
  @include travel-and-shop-container;

  &.single-view {
    position: relative;
  }

  &.details-view,
  &.list-view {
    bottom: 0;
    left: 0;
    right: 0;
    top: 114px;
    height: calc(100% - 114px);

    @include mobtablet {
      height: calc(100% - 75px);
      top: 75px;
    }
    
    margin-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    @extend %flex-height;
    position: absolute;
    
    .offset-images {
      display: none;
    }

    .travel-planner-block-container {
      @extend %flex-height;
    }
    .travel-planner-results-container {
      @extend %flex-height;
      > .row {
        width: 100%;
      }
    }
    .travel-results-list {
      overflow: hidden;
      @extend %flex-height;
      > div {
        @extend %flex-height;
      }
    }
    .travel-results-view-container {
      @extend %flex-height;
      max-height: none;
      height: 0px; // IE hack
      .travel-results {
        overflow-y: scroll;
        flex: 1 0 0; // firefox hack
      }
    }

    + * {
      display: none;
    }
  }

  &.details-view {
    display: block;
    overflow: auto;
    .travel-planner-block-container,
    .travel-planner-results-container,
    .travel-results-container,
    .travel-results-block-container,
    .travel-results-view-container,
    .travel-results-details-outer {
      height: auto;
      max-height: none;
    }
    .travel-planner-block-container,
    .travel-planner-results-container,
    .travel-results-container,
    .travel-results-view-container {
      display: block;
      flex: none;
    }

    .travel-results-details-container {
      flex: none;
    }
  }

  .travel-planner-block-container {
    width: 650px;
    @include mobile {
      width: 100%;
      max-width: 650px;
    }
  }
  .offset-images {
    @include mobtablet {
      display: none;
    }
    position: absolute;
    width: 650px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: -1;
    pointer-events: none;

    div {
      text-align: left;
      position: relative;
      height: 3rem;
      width: 325px;
      img {
        position: absolute;
        max-width: 100%;
        width: 100%;
        transform: translate(0, -50%);
        transition: opacity 0.3s ease-out;
      }
      opacity: 1;
      position: relative;
    }
    .from {
      flex: 1;
      order: -1;
      left: -70%;
    }
    .to {
      flex: 1;
      order: 1;
      right: -60%;
    }

    &.switch {
      .from {
        order: 1;
        left: auto;
        right: -70%;
      }
      .to {
        order: -1;
        right: auto;
        left: -60%;
      }
    }
    &.list {
      .from,
      .to {
        opacity: 0;
        transform: none;
      }
    }

    @include mobtablet {
      top: 7rem;
      &.switch .from,
      &.switch .to,
      .from,
      .to {
        left: auto;
        right: auto;
        img {
          max-width: 30vw;
          position: relative;
          transform: translate(0, -50%);
        }
      }
      &.switch .to,
      .from {
        text-align: left;
      }
      &.switch .from,
      .to {
        text-align: right;
      }
    }
  }
  &.single-view .travel-planner-results-container {
    position: relative;
    box-shadow: 0 10px 14px 0 rgba(48, 62, 72, 0.45);
    &.warning {
      box-shadow: none;
    }
  }
  .travel-warning {
    color: $FT_white;
    line-height: 1.5;
    margin-bottom: 2rem;
    //margin-top: -2rem;
    max-width: 650px;
    width: 100%;
    @include tablet {
      margin-bottom: 1.5rem;
      margin-top: -0.9rem;
    }
    @include mobile {
      line-height: 1.2;
      padding: 0 10px;
    }
  }
}

.travel-list-planner-view {
  overflow-y: hidden;
}

.travel-planner-results-container {
  width: 650px;
  position: relative;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  @include mobile {
    width: calc(100% - 20px);
    max-width: 650px;
    margin-top: 10px auto 0;
    padding-top: 10px;
    [class^="col-xs-"] {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.alert .row:first-child {
    background: $FT_black;
    color: $FT_white;
  }
}

.travel-results-datepicker-container.row {
  top: 0;
  left: 50%;
  bottom: 0;
  z-index: 10;
  width: 100%;
  max-width: 650px;
  .lightbox-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }
  .travel-results-datepicker {
    position: absolute;
    left: 50%;
    width: 100%;
    max-width: 650px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
    transform: translate(-50%, 0);
    z-index: 999;

    top: 0;
    overflow-y: auto;
    background: $FT_white;
    padding: 0 $site-column-gutter;
    @include mobile {
      padding: 0 $site-column-gutter-mobile-travel-planner;
      max-height: calc(100% - 15px);
    }
    h5 {
      position: relative;
      &:before {
        position: absolute;
        left: -2rem;
        top: 50%;
        content: "!";
        background: $FT_white;
        border-radius: 100%;
        width: 1.5em;
        height: 1.5em;
        margin: 0 10px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5em;

        margin-top: -1.875em;
        @include mobile {
          margin: 0 5px;
          margin-top: -1.875em;
          left: -1.5rem;
        }
        display: block;
        background: $FT_dev_yellow_dark;
        font-weight: bold;
        color: $FT_dev_green_text; // yes, green is correct here
      }
      font-weight: normal;
      padding: 0 1rem 2rem;
      text-align: left;
    }
  }
}

.shop-btn-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.5rem;
  @include tablet {
    margin-top: 2.5rem;
  }
  @include mobile {
    margin-top: calc(2.5rem - 10px);
  }
}
