.image-list-block-container {
  padding: $spacing-block-tmp 0;

  .image-list-block-title {
    margin-bottom: $spacing-block-tmp;
  }
  .image-item-container {
    margin-bottom: $spacing-text-xl;
  }

  .image-container {
    margin-bottom: $spacing-text-m;
  }
  .image-item-title {
    margin-bottom: $spacing-text-s;
  }

  .image-mobile-download-link {
    display: none;
    @include mobile {
      display: block;
    }
  }

  @include mobtablet {
    padding: $spacing-mobile-block-l 0;
    .image-list-block-title {
      margin-bottom: $spacing-mobile-block-m;
    }
  }
}
