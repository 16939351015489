.feature-block {
  padding: $spacing-block-tmp 0;
  background: $FT_grey_light;

  &.type-white {
    background: $FT_white;
  }

  &.type-grey {
    background: $FT_grey_light;
  }
  &.type-beige {
    background: $FT_beige_light;
  }
  &.type-limestone {
    background: $FT_limestone_light;
  }

  .feature-block-title {
    margin-bottom: $spacing-block-xs;
    text-align: center;

    .col-xs-12 {
      display: block;
    }
    h1 {
      display: inline;
      &:hover {
        color: $FT_orange_link;
        border-bottom: solid 1px $FT_orange_link;
      }
    }
  }

  .feature-block-subtitle {
    display: inline;

    &:hover {
      color: $FT_orange_link;
      border-bottom: solid 1px $FT_orange_link;
    }
    &:after {
      content: "";
      display: block;
      margin-bottom: $spacing-text-m;
    }
  }

  .contextual-text {
    color: $FT_shale;
    margin-bottom: $spacing-text-m;
  }

  .feature-block-link {
    margin-top: $spacing-text-s;
  }

  .feature-text {
    font-size: 18px;
    line-height: 1.67;

    @include mobtablet {
      font-size: 15px;
      font-weight: 300;
      line-height: 1.47;
    }
  }

  .image-container {
    width: 100%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    &:after {
      padding-top: $FT_image_ratio;
      content: "";
      display: block;
    }
  }

  @include mobtablet {
    padding: $spacing-mobile-block-m 0;

    .off-sm-1 {
      margin: 0;
    }

    .feature-block-title {
      margin-bottom: $spacing-mobile-block-m;
      text-align: left;
    }

    .contextual-text {
      margin-bottom: $spacing-mobile-text-l;
    }

    .image-container {
      margin-bottom: $spacing-mobile-block-s;
    }
  }
}
