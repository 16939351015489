.travel-results-container {
  position: relative;
  line-height: 1;
  text-align: center;
  > div {
    width: 100%;
  }
}

.travel-planner.details-view .travel-results-block-container {
  &.view-details {
    .travel-results {
      flex: none;
      height: auto;
      overflow-y: auto;
    }
  }
}

.travel-planner .center-col {
  &[class^="col-xs-"] {
    padding: 0;
  }
  button {
    @include button_stripped;
    color: $FT_black;
    font-size: inherit;
    font-weight: 300;
    margin-top: -($spacing-text-l);
    cursor: pointer;
    &:hover,
    &:focus {
      .no-touch & {
        outline: none;
        color: $FT_orange;
      }
    }
  }
}
.travel-results-button {
  .list-view & {
    opacity: 0;
    visibility: hidden;
  }
}

.travel-results-deviation-list {
  text-align: left;
  width: 100%;
  margin: $spacing-mobile-text-s 0;
  + .travel-results-deviation-list {
    margin-top: 0;
  }
  &:first-child {
    margin-top: 0;
  }
  .list-view & {
    margin-bottom: $spacing-block-xxs;
  }
  &:last-child {
    margin-bottom: 0;
    .single-view & {
      .deviation-header {
        top: auto !important;
      }
      margin-top: 0;
    }
  }
  .deviation-header {
    .title {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      min-height: 25px;

      font-weight: 400;
      line-height: 20px;
      padding-right: $spacing-text-xl;
      font-size: 14px;
    }
    &.deviation-header-copy {
      display: none;
    }
  }
  &.sticky {
    .deviation-header {
      position: fixed;
      top: auto;
      &.deviation-header-copy {
        position: static;
        display: block;
        visibility: hidden;
      }
    }
  }
}
.travel-results {
  position: relative;
  -webkit-overflow-scrolling: touch;

  &.empty-list {
    background: $FT_white;
  }
  > li,
  ul li {
    display: block;
  }
  position: relative;
  text-align: left;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .single-view & {
    overflow: visible;
  }

  &.server-error {
    position: relative;
    z-index: 99;
  }
  .deviation-header {
    position: sticky;
    top: 42px;
    z-index: 90;
  }

  &.server-error,
  .deviation-header {
    display: block;
    cursor: pointer;
    line-height: 24px;
    font-size: 15px;
    padding: ($spacing-text-s - 1) 0;
    padding-left: 40px;
    @include mobile {
      font-size: 12px;
      padding-left: 35px;
    }
    &:hover,
    &:focus {
      outline: none;
    }
    overflow: hidden;
    svg {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 16px;
      height: 16px;
      transform-origin: 50% 50%;
      transform: rotate(180deg);
      transition: all 0.2s ease-in-out;
    }
    .description {
      transition: all 0.2s ease-in;
      display: none;
      line-height: 20px;
      padding: $spacing-text-s $spacing-text-xl $spacing-text-s 0;
      font-family: inherit;
    }
    &.is-open {
      .description {
        display: block;
      }
      svg {
        transform: none;
      }
    }
    &:before {
      position: absolute;
      left: 0;
      content: "!";
      background: $FT_white;
      border-radius: 100%;
      width: 1.5em;
      height: 1.5em;
      margin: 0 10px;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5em;

      @include mobile {
        margin: 0 5px;
      }
    }
    &.deviation-ok,
    &.deviation-warning,
    &.deviation-error {
      background: $FT_dev_yellow;
      color: $FT_dev_yellow_text;
      &:before {
        background: $FT_dev_yellow_dark;
        color: $FT_dev_green_text;
      }
      &:hover,
      &:focus {
        .no-touch & {
          outline: none;
          background: $FT_dev_yellow_dark;
          &:before {
            background: $FT_white;
            color: $FT_black;
          }
        }
      }
    }
  }
  &.server-error {
    background: $FT_white;
    padding: 0 $spacing-block-s;
    @include mobile {
      padding: 0 $spacing-mobile-block-m !important;
      font-size: 20px;
    }
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    height: auto;
    line-height: 1.5;
    &:before {
      position: relative;
      display: block;
      background: $FT_dev_yellow_dark;
      font-size: 24px;
      font-weight: bold;
      color: $FT_dev_green_text;
      margin-bottom: $spacing-block-xs;
      margin-top: $spacing-block-xs;
      @include mobile {
        margin-bottom: $spacing-mobile-block-m;
      }
    }
    margin-bottom: 0;
    padding-bottom: $spacing-block-s;

    .list-view & {
      padding: $spacing-block-xxs;
      &:before {
        margin: $spacing-block-xxs;
      }
    }
  }
}

.date-label-container {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
}
.date-label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 42px;
  padding: $spacing-text-s;
  cursor: pointer;

  background: $FT_anthracite;
  color: $FT_white;
  z-index: 100;
  svg {
    width: 1em;
    height: 100%;
    fill: $FT_white !important;
    margin-right: $spacing-text-s;
  }
  &:hover,
  &:focus {
    .no-touch & {
      outline: none;
      text-decoration: underline;
    }
  }
}

.date-label-loading-container{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.1s ease-in;
  opacity: 0;

  .date-label--loading &{
    opacity: 1;
  }
}

.date-label-text-container{
  transition: opacity 0.1s ease-in;
  opacity: 1;

  .date-label--loading &{
    opacity: 0;
  }
}

.list-view .travel-result {
  + .travel-results-deviation-list {
    margin-top: $spacing-block-xxs;
  }
}

.travel-results-button-container {
  position: relative;
  background: $FT_white;
  .travel-results & {
    background: inherit;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 55px;

  .buttons {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    .list-view &,
    .details-view & {
      box-shadow: 0 30px 50px 10px;
    }
  }
  &.loading {
    text-align: center;
    .travel-results-link {
      opacity: 0;
    }
    .travel-results-loader {
      opacity: 1;
    }
  }
}

.travel-results-loader {
  transition: opacity 0.1s ease-in;
  opacity: 0;
}

.travel-cal-link,
.travel-results-link {
  font-size: 16px;
  color: $FT_anthracite;
  opacity: 1;
  transition: opacity 0.3s ease-in;
  flex: 1;
  text-align: right;
  font-family: inherit;
  font-weight: inherit;
  .list-view & {
    text-align: center;
  }
  @include mobile {
    font-size: 14px;
  }
  text-align: center;
  padding: $spacing-text-s $site-column-gutter-travel-planner;
  @include mobile {
    padding: $spacing-text-s $site-column-gutter-mobile-travel-planner;
  }
}
.travel-cal-link {
  color: $FT_grey;
  text-align: left;
  padding-right: 0;
  @include desktop {
    display: none;
    padding: $spacing-text-s 0;
  }
}
.travel-results-link {
  padding: $spacing-text-m 0;

  &:hover,
  &:focus {
    .no-touch & {
      outline: none;
      text-decoration: underline;
    }
  }
}