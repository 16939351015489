.search-main-title {
  display: none;
}

.search-wrapper {
  margin-bottom: $spacing-block-s;

  form {
    display: flex;
    align-items: flex-end;
    background: $FT_grey_light;
    padding: $spacing-block-xs $spacing-text-xl;

    input {
      background: $FT_grey_light;
      color: $FT_anthracite;
      font-size: 50px;
      font-weight: 100;
      padding: $spacing-text-s 0;
      border: 0;
      border-bottom: solid 1px $FT_anthracite;
      width: 100%;
    }

    .search-btn {
      font-size: 14px;
      line-height: 14px;
      height: 50px;
    }
  }

  @include mobtablet {
    margin-bottom: $spacing-mobile-block-l;
    form {
      flex-flow: column;
      align-items: center;
      padding: $spacing-mobile-block-m $spacing-mobile-text-m;

      input {
        font-size: 30px;
        padding: $spacing-mobile-text-s 0;
        margin-bottom: $spacing-mobile-text-l;
      }
      .search-btn {
        width: 100%;
      }
    }
  }
}
.search-results-wrapper {
  margin-bottom: $spacing-block-s;
}

.search-results-amount {
  margin-top: $spacing-text-l;

  @include mobtablet {
    margin-top: $spacing-mobile-text-m;
  }
}

.list-search {
  li {
    display: block;
  }
}

.list-item {
  margin-bottom: $spacing-block-xs;

  @include mobtablet {
    margin-bottom: $spacing-mobile-block-m;
  }
}

.list-item-header a {
  color: $FT_orange_link;
  display: inline;
  &:hover {
    border-bottom: solid 1px $FT_orange_link;
  }
}
