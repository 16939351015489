.travel-result {
  background: $FT_white;
  width: 100%;

  font-size: 35px;
  @include mobile {
    font-size: 20px;
  }
  border-bottom: solid 1px $FT_limestone;
  padding-top: $spacing-text-m;
  padding-bottom: $spacing-text-m;
  cursor: pointer;

  &.travel-result-past{
    background: $FT_past-travel-results_bg !important;
    border-bottom-color: #cbcdd5;
    color: $FT_past-travel-results_color;
    
    svg{
      fill: $FT_past-travel-results_color;
    }

    &:hover {
      .no-touch & {
        background: $FT_limestone_light !important;
      }
    }


    &:focus {
      .no-touch:not(.mouse-user) & {
        background: $FT_limestone_light !important;
      }
    }

  }

  &:hover {
    outline: none;
    .no-touch & {
      background: $FT_limestone_light;
    }
  }

  &:focus {
    outline: none;
    .no-touch:not(.mouse-user) & {
      background: $FT_limestone_light;
    }
  }


  [class^="col-xs-"] {
    &.center-col {
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      > button {
        text-decoration: underline;
      }
    }
    @include mobile {
      &.departure {
        padding-right: 0;
      }
      &.arrival {
        padding-left: 0;
        text-align: right;
      }
    }
    &.arrival {
      text-align: right;
    }
  }

  .single-view & {
    background: $FT_white;
    border-bottom: none;
    padding-top: $spacing-text-xl;
    padding-bottom: 0;

    .primary-row {
      .center-col {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 80%;
          width: 100%;
          height: 1px;
          background: black;
        }
      }
    }
    .secondary-row {
      display: flex;
    }

    &.deviation-cancelled {
      .primary-row .center-col:after {
        display: none;
      }
    }
  }

  // overrides for trips with deviations
  &.deviation {
    .primary-row .departure {
      position: relative;
      &:before {
        position: absolute;
        left: 0;

        top: 5px;
        content: "!";
        background: $FT_white;
        border-radius: 100%;
        width: 1.5em;
        height: 1.5em;
        margin: 0 9px;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5em;

        @include mobile {
          font-size: 12px;
          top: 0px;
          margin: 0 6px;
        }
      }
    }

    &.deviation-cancelled,
    &.deviation-alternative {
      background: $FT_dev_yellow;
      &:hover,
      &:focus {
        background: #f9e6c0;
      }

      .departure:before {
        background: $FT_dev_yellow_dark;
        color: $FT_dev_green_text;
      }
    }

    &.deviation-cancelled {
      .departure,
      .arrival {
        text-decoration: line-through;
      }
    }
  }

  .center-col {
    padding: 0 !important;
    align-items: center;
    justify-content: center;
    > span {
      font-size: 20px;
    }
    > svg {
      transition: all 0.3s ease-in;
      opacity: 0;
      transform: scale(0);
      max-width: 31px;
      height: 30px;
      @include mobile {
        display: none;
      }
    }
  }

  @include mobile {
    & .travel-result + {
      margin-top: $spacing-text-m;
    }
    padding-top: $spacing-text-s;
    padding-bottom: $spacing-text-s;
  }

  .secondary-row {
    transition: max-height 0.1s ease-in-out;
    display: none;
    font-size: 14px;
    line-height: 1;
    > * {
      display: block;
    }
  }

  .date,
  .track {
    display: block;
    font-size: 16px;
    @include mobile {
      font-size: 14px;
    }
  }
  .date {
    margin: $spacing-mobile-text-l 0 $spacing-mobile-text-m;
    .label {
      display: inline-block;
      margin-right: $spacing-text-s;
      @include mobile {
        margin-right: $spacing-mobile-text-s;
      }
    }
    .icon-cal-small {
      display: inline-block;
      width: 20px;
      height: 20px;
      @include mobile {
        width: 17px;
        height: 17px;
      }
      svg {
        fill: $FT_orange;
        margin-bottom: -4px;
      }
    }
  }
  .track {
    margin: ($spacing-mobile-text-m - 1) 0 $spacing-mobile-text-s;
  }
}

.arrival {
  position: relative;
  svg {
    display: none;
  }
  .view-list & {
    svg {
      display: block;
      position: absolute;
      transform: rotate(90deg);
      right: 10px;
      font-size: 20px;
      width: 1em;
      height: 1em;
      top: 50%;
      margin-top: -0.5em;
      @include mobile {
        font-size: 14px;
      }
    }
  }
}
