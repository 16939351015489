.super-mega-heading-block {
  padding: 0 0 $spacing-block-s;

  h3 {
    color: $FT_shale;
    margin-bottom: $spacing-text-s;
  }

  img {
    width: 40px;
    height: auto;
  }

  h3 + .quote {
    margin-top: 40px;
  }

  .quote {
    @include mobtablet {
      font-size: 35px;
      line-height: 1.49;
    }
    @include mobile {
      font-size: 25px;
      line-height: 1.4;
    }
  }

  @include mobtablet {
    padding: $spacing-mobile-block-l 0 $spacing-mobile-block-xl;

    h3 {
      margin-bottom: 20px;
    }
    .quote {
      margin-top: 20px;
    }
  }
}
