.breadcrumbs {
  $self: &;
  margin: $spacing-text-m 0 $spacing-block-xs;

  @include mobtablet {
    margin: $spacing-mobile-text-xl 0 $spacing-mobile-block-s;
  }

  &__heading {
    @include FT_breadcrumbs;
    display: inline-block;
    margin-right: $spacing-text-s;

    #{$self}_in-content-container & {
      color: $FT_white;
    }
  }

  &__items {
    @include FT_breadcrumbs;
    display: inline-block;
    @media (min-width: #{$screen-lg}) {
      font-size: 18px;
    }
  }

  &__current-item-text {
    font-size: 18px;
    line-height: 1.67;
    @include mobtablet {
      font-size: 12px;
      line-height: 1.67;
    }
    #{$self}_in-content-container & {
      color: $FT_white;
    }
  }

  &__link {
    @include cta;
    @include mobtablet {
      font-size: 12px;
      line-height: 1.67;
    }
    #{$self}_in-content-container & {
      color: $FT_white;
    }
  }

  &__item {
    #{$self}_in-content-container & {
      color: $FT_white;
    }
    &:after {
      content: "\00a0/\00a0";
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }
}