//colors
$color_silver_chalice_approx: #aeaeae;
$color_gallery_approx: #f0f0f0;
$color_celeste_approx: #ccc;
$color_nobel_approx: #b3b3b3;
$color_chateau_green_approx: #3dcc4a;
$color_apple_approx: #32be3f;
$color_matisse_approx: #216ba5;
$color_matisse_50_approx: rgba(33, 107, 165, 0.5);

//fonts
$font_0: Helvetica Neue;
$font_1: Helvetica;
$font_2: Arial;
$font_3: sans-serif;

%hover-state {
  &:before {
    border-color: $FT_orange;
    border-width: 3px;
  }
  background-color: transparent;
  color: $FT_orange;
}

%block-state {
  $w: 100% / 8;
  width: $w;
  text-align: center;
}
%extend_1 {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

%extend_2 {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: $color_silver_chalice_approx;
}

%extend_4 {
  border-width: 3px;
  border-color: $FT_orange;
  color: $FT_white;
}

%extend_5 {
  border-top-color: $color_celeste_approx;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

%extend_6 {
  background-color: $color_gallery_approx;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  text-align: center;
}

%extend_7 {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

%extend_8 {
  user-select: none;
}

.react-datepicker__tether-element-attached-top {
  .react-datepicker__triangle {
    margin-left: -8px;
    position: absolute;
    @extend %extend_1;
    top: 0;
    margin-top: -8px;
    border-top: none;
    border-bottom-color: $color_gallery_approx;
  }
  .react-datepicker__triangle::before {
    @extend %extend_1;
    @extend %extend_2;
    border-top: none;
    border-bottom-color: $color_gallery_approx;
    top: -1px;
    border-bottom-color: $color_silver_chalice_approx;
  }
}
.react-datepicker__tether-element-attached-bottom {
  .react-datepicker__triangle {
    margin-left: -8px;
    position: absolute;
    @extend %extend_1;
    bottom: 0;
    margin-bottom: -8px;
    border-bottom: none;
    border-top-color: $FT_white;
  }
  .react-datepicker__triangle::before {
    @extend %extend_1;
    @extend %extend_2;
    border-bottom: none;
    border-top-color: $FT_white;
    bottom: -1px;
    border-top-color: $color_silver_chalice_approx;
  }
  &.react-datepicker__tether-element {
    margin-top: -20px;
  }
}
.react-datepicker__year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
  @extend %extend_1;
  bottom: 0;
  margin-bottom: -8px;
  border-bottom: none;
  border-top-color: $FT_white;
  @extend %extend_5;
}
.react-datepicker__month-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
  @extend %extend_1;
  bottom: 0;
  margin-bottom: -8px;
  border-bottom: none;
  border-top-color: $FT_white;
  @extend %extend_5;
}
.react-datepicker__year-read-view--down-arrow::before {
  @extend %extend_1;
  @extend %extend_2;
  border-bottom: none;
  border-top-color: $FT_white;
  bottom: -1px;
  border-top-color: $color_silver_chalice_approx;
}
.react-datepicker__month-read-view--down-arrow::before {
  @extend %extend_1;
  @extend %extend_2;
  border-bottom: none;
  border-top-color: $FT_white;
  bottom: -1px;
  border-top-color: $color_silver_chalice_approx;
}
.react-datepicker {
  font-size: 35px;
  @include mobile {
    margin-top: $spacing-text-s;
    font-size: 20px;
  }

  background-color: $FT_white;
  color: $FT_black;
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: $spacing-text-l;
}
.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}
.react-datepicker {
  line-height: 1.9;
  padding-bottom: 0;
}
.react-datepicker__header {
  text-align: center;
  padding-top: 8px;
  position: relative;
}
.react-datepicker__year-dropdown-container--select {
  display: inline-block;
  margin: 0 2px;
}
.react-datepicker__month-dropdown-container--select {
  display: inline-block;
  margin: 0 2px;
}
.react-datepicker__year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}
.react-datepicker__month-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}
.react-datepicker__current-month {
  margin-top: 0;
  color: $FT_black;
  text-transform: capitalize;
  font-weight: 300;
}
.react-datepicker__current-month--value {
  display: inline-block;
  padding: 0 2rem;
  min-width: 247px;
  @include mobile {
    min-width: 102px;
    padding: 0 1rem;
  }
}
.travel-planner .react-datepicker__navigation {
  display: inline-block;
  vertical-align: middle;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
  z-index: 1;
  svg {
    width: 14px;
    height: 20px;
    fill: $FT_orange;
  }
  &:hover,
  &:focus {
    .no-touch & {
      outline: none;
      svg {
        fill: $FT_black;
      }
    }
  }
}
.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  svg {
    fill: $FT_orange;
  }

  &.react-datepicker__navigation--disabled {
    opacity: 0.5;
    cursor: default;

    svg,
    &:focus svg,
    &:hover svg {
      fill: $FT_grey;
    }
  }
}
.react-datepicker__month-container {
  margin-bottom: 30px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: $color_celeste_approx;
  &:hover {
    border-top-color: $color_nobel_approx;
  }
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: $color_celeste_approx;
  &:hover {
    border-bottom-color: $color_nobel_approx;
  }
}
.react-datepicker__month {
  text-align: center;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  justify-content: space-around;
  margin-top: 1vw;
}
.react-datepicker__day-names {
  margin-top: $spacing-text-l;
  margin-bottom: $spacing-text-m;
  @include mobile {
    font-size: 16px;
    margin-top: $spacing-text-m;
    margin-bottom: $spacing-text-s;
  }
}

.react-datepicker__week-number {
  color: $color_celeste_approx;
  display: inline-block;
  text-align: center;
}
.react-datepicker__day-name {
  @extend %block-state;
  text-transform: uppercase;
  font-size: 14px;
  @include mobile {
    font-weight: 100;
    color: $FT_grey;
    font-size: 13px;
  }
}
.react-datepicker__day {
  @extend %block-state;
  box-sizing: border-box;
  position: relative;
  font-size: 25px;
  @include mobile {
    font-size: 20px;
  }
  &:before {
    content: " ";
    position: absolute;
    border: solid 1px $FT_slate;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  cursor: pointer;
  color: $FT_anthracite;

  &:hover,
  &:focus {
    .no-touch & {
      outline: none;
      @extend %hover-state;
    }
  }
}
.react-datepicker__day,
.react-datepicker__day-name {
  width: 10.5%;
}
.react-datepicker__day--today {
  &:before {
    border-width: 3px;
  }
}
.react-datepicker__day--past{
  background-color: #d9d7dd;
}

.react-datepicker__day--highlighted {
  background-color: $color_chateau_green_approx;
  color: $FT_white;
  &:hover {
    background-color: $color_apple_approx;
  }
}

.react-datepicker__day--selected {
  @extend %extend_4;
  background-color: $FT_anthracite;
  color: $FT_white;
  &:before {
    border-color: $FT_anthracite;
  }
  &:hover,
  &:focus {
    .no-touch & {
      outline: none;
      @extend %hover-state;
      background-color: $FT_orange;
      color: $FT_white;
    }
  }
}
.react-datepicker__day--in-selecting-range {
  @extend %extend_4;
  &:hover {
    background-color: $color_matisse_approx;
  }
  &:not(.react-datepicker__day--in-range) {
    background-color: $color_matisse_50_approx;
  }
}
.react-datepicker__day--in-range {
  @extend %extend_4;
  &:hover {
    @extend %hover-state;
  }
}
.react-datepicker__week--disabled {
  display: none;
}
.react-datepicker__day--disabled {
  cursor: default;
  color: $color_celeste_approx;
  visibility: hidden;
  &:focus,
  &:hover {
    background-color: transparent;
  }
}
.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}
.react-datepicker__year-read-view {
  border: 1px solid transparent;
  &:hover {
    cursor: pointer;
    .react-datepicker__year-read-view--down-arrow {
      border-top-color: $color_nobel_approx;
    }
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: $color_nobel_approx;
    }
  }
}
.react-datepicker__month-read-view {
  border: 1px solid transparent;
  &:hover {
    cursor: pointer;
    .react-datepicker__year-read-view--down-arrow {
      border-top-color: $color_nobel_approx;
    }
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: $color_nobel_approx;
    }
  }
}
.react-datepicker__year-dropdown {
  @extend %extend_6;
  &:hover {
    cursor: pointer;
  }
}
.react-datepicker__month-dropdown {
  @extend %extend_6;
  &:hover {
    cursor: pointer;
  }
}
.react-datepicker__year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}
.react-datepicker__month-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}
.react-datepicker__year-option {
  @extend %extend_7;
  &:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  &:last-of-type {
    @extend %extend_8;
  }
  &:hover {
    background-color: $color_celeste_approx;
    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: $color_nobel_approx;
    }
    .react-datepicker__navigation--years-previous {
      border-top-color: $color_nobel_approx;
    }
  }
}
.react-datepicker__month-option {
  @extend %extend_7;
  &:first-of-type {
    //Instead of the line below you could use @include border-top-left-radius($radius)
    border-top-left-radius: 0.3rem;
    //Instead of the line below you could use @include border-top-right-radius($radius)
    border-top-right-radius: 0.3rem;
  }
  &:last-of-type {
    @extend %extend_8;
  }
  &:hover {
    background-color: $color_celeste_approx;
    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: $color_nobel_approx;
    }
    .react-datepicker__navigation--years-previous {
      border-top-color: $color_nobel_approx;
    }
  }
}
.react-datepicker__year-option--selected {
  position: absolute;
  left: 15px;
}
.react-datepicker__month-option--selected {
  position: absolute;
  left: 15px;
}
.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 0;
  outline: 0;
  padding: 0;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  background-color: $color_matisse_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 50%;
  bottom: 0;
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  color: $FT_white;
  content: "\00d7";
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 1;
  margin: -8px auto 0;
  padding: 2px;
  position: absolute;
  right: 7px;
  text-align: center;
  top: 50%;
}
.react-datepicker__today-button {
  background: $color_gallery_approx;
  border-top: 1px solid $color_silver_chalice_approx;
  cursor: pointer;
  text-align: center;
  padding: 5px 0;
  clear: left;
}
.react-datepicker__tether-element {
  z-index: 2147483647;
}
.react-datepicker__month--selecting-range
  .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
  background-color: $color_gallery_approx;
  color: $FT_black;
}
.react-datepicker__day--outside-month {
  color: $FT_limestone;
  &:before {
    border-color: $FT_limestone;
  }
}
